import * as React from 'react';
import {
    FC,
} from 'react';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router-dom';
import {
    GlobalAppState,
    GlobalContext,
} from '../../store/globalState';
import {
    displayErrorToast,
    displaySuccessToast,
} from '../../utils/toasts';
import ProtectedPageAuthPrompt from '../login/ProtectedPageAuthPrompt';
import './CoverReferenceView.scss';

interface CoverReferenceViewProps extends RouteComponentProps<any> {
}

const CoverReferenceView: FC<CoverReferenceViewProps> = (props) => {
    const {
        userAccount,
        userAccessToken,
        isUserLoggedIn,
        inheritedMsalInstance,
        testBackendApiHandlerNoAuth,
        testBackendApiHandlerWithAuth,
    } = React.useContext<GlobalAppState>(GlobalContext);

    const handleNoAuthClick = () => {
        testBackendApiHandlerNoAuth()
            .then((response) => {
                console.log(`response is ${JSON.stringify(response)}`);
            })
            .catch((error) => {
                console.error(`error is ${JSON.stringify(error)}`);
            });
    };

    const handleAuthClick = () => {
        if (inheritedMsalInstance) {
            testBackendApiHandlerWithAuth()
                .then((response) => {
                    console.log(`response is ${JSON.stringify(response)}`);
                })
                .catch((error) => {
                    console.error(`error is ${JSON.stringify(error)}`);
                });
        }
    };

    return (
        <div className="cover-reference-view">
            {
                !isUserLoggedIn &&
                <ProtectedPageAuthPrompt />
            }

            {
                isUserLoggedIn &&
                <>
                    <button
                        onClick={() => handleNoAuthClick()}
                    >
                        Test Backend API (no auth)
                </button>

                    <button
                        onClick={() => handleAuthClick()}
                    >
                        Test Backend API (with auth)
                </button>

                    <button
                        onClick={() => displaySuccessToast("success!!!")}
                    >
                        Success toast
                </button>

                    <button
                        onClick={() => displayErrorToast("error!!!")}
                    >
                        Error toast
                </button>

                    <p>
                        homeAccountId:
                    {
                            userAccount?.homeAccountId
                        }
                    </p>


                    <p>
                        localAccountId:
                    {
                            userAccount?.localAccountId
                        }
                    </p>

                    <p>
                        username:
                    {
                            userAccount?.username
                        }
                    </p>

                    <p>
                        userAccessToken:
                    {
                            userAccessToken
                        }
                    </p>
                </>
            }
        </div>
    );
};

export default withRouter(CoverReferenceView);