import * as React from "react";
import "./Spinner.scss";

interface SpinnerProps {
    dim?: boolean;
    light?: boolean;
    absolute?: boolean
}

//open source pure css spinners
//https://loading.io/css/
const Spinner: React.FC<SpinnerProps> = (props: SpinnerProps) => {
    return (
        <div className={"spinner" + (props.dim ? " dim" : "") + (props.light ? " light" : "") + (props.absolute ? " absolute" : "")}>
            <div className="lds-ripple"><div></div><div></div></div>
        </div>
    );
};

export default Spinner;