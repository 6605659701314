import * as React from 'react';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ProtectedPageAuthPrompt from '../../components/login/ProtectedPageAuthPrompt';
import ReportParser from '../../components/reportParsers/ReportParser';
import Spinner from '../../components/spinner/Spinner';
import { GlobalAppState, GlobalContext } from '../../store/globalState';

export interface ReportEntry {
    content: string;
    top: number;
    left: number;
    alignment: any;
    width: number;
    height: number;
    isLine: boolean;
    pageNumber: number;
    bold: boolean;
    italics: boolean;
}

export const getAlignment = (n: number) => {
    switch (n) {
        case 0:
            return 'left' as const;
        case 1:
            return 'right' as const;
        case 2:
            return 'center' as const;
        default:
            return 'left' as const;
    }
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Report: FC = () => {
    const {
        isUserLoggedIn,
        getReport,
        report,
        loadingReport,
        axiosInterceptorLoaded
    } = React.useContext<GlobalAppState>(GlobalContext);
    const query = useQuery();

    const urlParam = query.get("url");

    //gets the report from the datalake
    useEffect(() => {
        if (axiosInterceptorLoaded) {
            if (urlParam) {
                getReport(urlParam);
            }
        }

    }, [getReport, urlParam, axiosInterceptorLoaded]);

    return (
        !isUserLoggedIn ?
            <ProtectedPageAuthPrompt />
            :
            <>
                {
                    loadingReport ?
                        <Spinner />
                        :
                        report.length ?
                            <ReportParser reportEntries={report} />
                            :
                            <div>No report selected...</div>
                }
            </>
    );
};

export default Report;