import * as React from 'react';
import {
    useContext,
} from 'react';
import {
    Link,
    NavLink,
    RouteComponentProps,
    withRouter,
} from 'react-router-dom';
import {
    Collapse,
    Container,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
} from 'reactstrap';
import {
    logo,
} from "../../images";
import {
    GlobalAppState,
    GlobalContext,
} from '../../store/globalState';
import
AuthenticationHandler, {
    AuthenticationOperation
} from '../login/AuthenticationHandler';
import './NavMenu.scss';

interface NavMenuProps extends RouteComponentProps<any> {
}

const NavMenu: React.FC<NavMenuProps> = () => {
    const [collapsed, setCollapsed] = React.useState(Boolean);
    const {
        isUserLoggedIn,
        inheritedMsalInstance,
        userAccount
    } = useContext<GlobalAppState>(GlobalContext);

    return (
        <header>
            <Navbar
                className="navbar navbar-expand-sm navbar-toggleable-sm box-shadow"
                light
            >
                <Container>
                    <NavbarBrand
                        tag={Link}
                        to="/search"
                    >
                        <img
                            className="logo"
                            src={logo}
                            alt="NZbrokers Logo"
                        />
                    </NavbarBrand>

                    <NavbarToggler
                        onClick={() => setCollapsed(!collapsed)}
                        className="mr-2"
                    />

                    <Collapse
                        className="d-sm-inline-flex flex-sm-row"
                        isOpen={!collapsed}
                        navbar
                    >
                        <ul
                            className="navbar-nav flex-grow"
                        >
                            {
                                isUserLoggedIn &&
                                <NavItem>
                                    <NavLink
                                        to="/search"
                                        activeClassName="active-nav-item"
                                        exact={true}
                                    >
                                        Search
                                </NavLink>
                                </NavItem>
                            }
                        </ul>
                    </Collapse>
                    {
                        isUserLoggedIn && inheritedMsalInstance && userAccount &&
                        <>
                            <span className="username">{userAccount.username}</span>
                            <AuthenticationHandler
                                msalInstance={inheritedMsalInstance}
                                operation={AuthenticationOperation.NAVBAR_LOGOUT}
                            />
                        </>
                    }
                </Container>
            </Navbar>
        </header>
    );
};

export default withRouter(NavMenu);