import * as React from 'react';
import {
    FC,
    useContext,
} from 'react';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router-dom';
import {
    GlobalAppState,
    GlobalContext,
} from "../../store/globalState";
import AuthenticationHandler, { AuthenticationOperation } from './AuthenticationHandler';
import './Login.scss';

interface LoginProps extends RouteComponentProps<any> {
}

const Login: FC<LoginProps> = () => {
    const {
        isUserLoggedIn,
        inheritedMsalInstance,
    } = useContext<GlobalAppState>(GlobalContext);

    return (
        <div className="login">
            <div className="login-left">
            </div>

            <div className="login-right">
                <div className="login-top">
                    <h1 className="title">
                        Welcome to the NZbrokers Web Portal
                    </h1>

                    <div>
                        <p>
                            Please sign in to continue.
                        </p>
                    </div>

                    <div>
                        {
                            isUserLoggedIn && inheritedMsalInstance &&
                            <AuthenticationHandler
                                msalInstance={inheritedMsalInstance}
                                operation={AuthenticationOperation.LOGIN_PAGE_LOGOUT}
                            />
                        }

                        {
                            !isUserLoggedIn && inheritedMsalInstance &&
                            <AuthenticationHandler
                                msalInstance={inheritedMsalInstance}
                                operation={AuthenticationOperation.LOGIN_PAGE_LOGIN}
                            />
                        }
                    </div>
                </div>

                <div className="login-bottom">
                    <div className="login-bottom-middle">
                    </div>

                    <div className="login-bottom-right">
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Login);