export const getInvoiceWithNoPrefix = (searchText: string) => {
    if (searchText) {
        const firstChar = searchText.charAt(0).toLocaleLowerCase();
        switch (firstChar) {
            case "i":
                return searchText.substr(1);
            case "m":
                return searchText.substr(1);
            default:
                return searchText;
        }
    }
    return "";
};
