import {
    toast
} from 'react-toastify';

export const displaySuccessToast = (message: string) => {
    toast.success(
        message,
        {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true
        }
    );
};

export const displayErrorToast = (errorMessage: string) => {
    toast.error(
        errorMessage,
        {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true
        }
    );

    console.error(`encountered the following error: ${errorMessage}`);
};

