import {
    AccountInfo,
    EndSessionRequest,
    InteractionRequiredAuthError,
    IPublicClientApplication,
    PopupRequest,
    PublicClientApplication,
} from '@azure/msal-browser';
import React, { FC } from 'react';
import {
    buildWebApiTokenRequest,
    loginRequest,
} from './AuthConfig';
import {
    GlobalAppState,
    GlobalContext,
} from '../../store/globalState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { iconLogout } from '../../images';

export enum AuthenticationOperation {
    "UNKNOWN",
    "NAVBAR_LOGOUT",
    "LOGIN_PAGE_LOGIN",
    "LOGIN_PAGE_LOGOUT",
    "PROTECTED_PAGE_LOGIN",
}

interface AuthenticationHandlerProps extends RouteComponentProps<any> {
    operation: AuthenticationOperation,
    msalInstance: PublicClientApplication,
}

const AuthenticationHandler: FC<AuthenticationHandlerProps> = (props: AuthenticationHandlerProps) => {
    const {
        loginSuccessful,
        loginUnsuccessful,
        logoutSuccessful,
        logoutUnsuccessful,
        appSettings,
        userAccount,
        setAccountToUse
    } = React.useContext<GlobalAppState>(GlobalContext);

    const performLogin = (msalInstance: IPublicClientApplication, props: any) => {
        if (appSettings) {

            msalInstance
                .loginPopup(loginRequest(appSettings.scopes))
                .then((loginResponse) => {
                    if (loginResponse) {
                        // Redirect the user to X page so that they don't have to manually navigate
                        props.history.push("/search");

                        if (loginResponse?.account?.username) {
                            const userAccountInfo: AccountInfo | null = msalInstance.getAccountByUsername(loginResponse?.account?.username);

                            if (userAccountInfo) {
                                const tokenRequest: PopupRequest = buildWebApiTokenRequest(userAccountInfo, appSettings.scopes);

                                msalInstance
                                    .acquireTokenSilent(tokenRequest)
                                    .then((tokenResponse) => {
                                        loginSuccessful(tokenResponse?.accessToken);

                                        if (userAccount?.homeAccountId !== tokenResponse.account?.homeAccountId && !!tokenResponse.account) {
                                            setAccountToUse(tokenResponse.account);
                                        }
                                    })
                                    .catch(async (error) => {
                                        if (error instanceof InteractionRequiredAuthError) {
                                            // fallback to interaction when silent call fails
                                            const popupAuthenticationResult = await msalInstance.acquireTokenPopup(tokenRequest);
                                            loginSuccessful(popupAuthenticationResult.accessToken);
                                        }
                                    })
                                    .catch((error) => {
                                        console.error(`error is ${JSON.stringify(error)}`);
                                    });
                            }
                        }
                    }
                })
                .catch((error) => {
                    // Print the error message to help with debugging login issues
                    console.error(`errorMessage is ${JSON.stringify(error.errorMessage)}`);
                    loginUnsuccessful(error.errorMessage);
                });
        }
    };

    const performLogout = (msalInstance: IPublicClientApplication) => {
        try {
            const logoutRequest: EndSessionRequest = {
                postLogoutRedirectUri: "/"
            };

            msalInstance.logout(logoutRequest);

            logoutSuccessful();
        }
        catch (error) {
            console.error(`caught error was ${JSON.stringify(error)}`);
            const err = error as any;
            logoutUnsuccessful(err);
        }
    };

    const handleLoginRequest = () => {
        performLogin(props.msalInstance, props);
    };

    const handleLogoutRequest = () => {
        performLogout(props.msalInstance);
    };

    return (
        <div>
            {
                ((props.operation === AuthenticationOperation.PROTECTED_PAGE_LOGIN) ||
                    (props.operation === AuthenticationOperation.LOGIN_PAGE_LOGIN)) &&
                <button
                    className="btn-signin btn-auth-action"
                    onClick={() => handleLoginRequest()}
                >
                    Sign In
                </button>
            }

            {
                props.operation === AuthenticationOperation.LOGIN_PAGE_LOGOUT &&
                <button
                    className="btn-signout btn-auth-action"
                    onClick={() => handleLogoutRequest()}
                >
                    Sign Out
                </button>
            }

            {
                props.operation === AuthenticationOperation.NAVBAR_LOGOUT &&
                <button
                    className="nav-signout"
                    onClick={() => handleLogoutRequest()}
                >
                    <img src={iconLogout} alt="logout" />
                </button>
            }
        </div>
    );
};

export default withRouter(AuthenticationHandler);