import * as React from 'react';
import { FC, useContext, useState } from 'react';
import './SearchResults.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Collapse } from 'reactstrap';
import { PolicySearchResult } from '../../types/PolicySearchResult';
import { useEffect } from 'react';
import { GlobalAppState, GlobalContext } from '../../store/globalState';
import Spinner from '../spinner/Spinner';
import ReportResultsTable from './tables/ReportResultsTable';
import PolicyResultsTable from './tables/PolicyResultsTable';
import PolicyVersionResultsTable from './tables/PolicyVersionResultsTable';
import { Link } from 'react-router-dom';
import { SearchFilter, SearchType } from '../../pages/search/Search';
import moment from 'moment';

interface SearchResultsProps {
    lastSearchType: SearchType | undefined;
}

export const sortByPreferredOrder = (x: PolicySearchResult[], sortingArray: string[]) => {
    x.sort((a, b) => {
        if (!sortingArray.includes(a.type) && sortingArray.includes(b.type)) { return 1; }
        if (!sortingArray.includes(b.type) && sortingArray.includes(a.type)) { return -1; }
        return sortingArray.indexOf(a.type) - (sortingArray.indexOf(b.type));
    });
    return x;
};

const SearchResults: FC<SearchResultsProps> = (props: SearchResultsProps) => {
    const {
        setSelectedPolicy,
        selectedPolicy,
        policySearchQuery,
        loadingPolicySearch,
        loadingReportSearch,
        reportSearchQuery,
        reportSearchResults,
        setSelectedReport,
        selectedReport,
        policySearchResults
    } = useContext<GlobalAppState>(GlobalContext);

    const [resultsOpen, setResultsOpen] = useState(true);
    const [versionOpen, setVersionOpen] = useState(false);
    const [selectedResult, setSelectedResult] = useState<PolicySearchResult | undefined>();
    const [versions, setVersions] = useState<PolicySearchResult[]>([]);



    useEffect(() => {
        //resets on new search
        setSelectedResult(undefined);
        setSelectedPolicy(undefined);
    }, [policySearchResults, setSelectedResult, setSelectedPolicy]);

    useEffect(() => {
        if (!!policySearchResults.length && selectedResult) {
            let relatedItems: PolicySearchResult[];
            const typeOrder = ["PDV", "DebitInvoice", "CreditInvoice", "InsurerClosing", "FeeCredit", "FeeDebit", "DirectClientInvoice", "WebForm"];

            switch (policySearchQuery.filter) {
                case SearchFilter.PolicyNumber:
                    relatedItems = Object.entries(
                        policySearchResults
                            //groups by x
                            .reduce(function (r, a) {
                                if (a.companyCode === selectedPolicy?.companyCode && a.branchCode === a.branchCode && a.policyNo === selectedPolicy?.policyNo) {
                                    r[a.companyCode + a.branchCode + a.coverNo + a.policyNo + a.versionNo] = r[a.companyCode + a.branchCode + a.coverNo + a.policyNo + a.versionNo] || [];
                                    r[a.companyCode + a.branchCode + a.coverNo + a.policyNo + a.versionNo].push(a);
                                }
                                return r;
                            }, Object.create(null))
                    ).flatMap((x: any) => sortByPreferredOrder(x[1], typeOrder)[0]
                    )
                        .sort((a: PolicySearchResult, b: PolicySearchResult) => moment(b.effectiveDate).isSameOrBefore(moment(a.effectiveDate)) ? -1 : 1 || b.versionNo.localeCompare(a.versionNo));

                    break;

                case SearchFilter.CoverNumber:
                    relatedItems = Object.entries(
                        policySearchResults
                            //groups by x
                            .reduce(function (r, a) {
                                if (a.companyCode === selectedPolicy?.companyCode && a.branchCode === a.branchCode && a.coverNo === selectedPolicy?.coverNo) {
                                    r[a.companyCode + a.branchCode + a.coverNo + a.versionNo] = r[a.companyCode + a.branchCode + a.coverNo + a.versionNo] || [];
                                    r[a.companyCode + a.branchCode + a.coverNo + a.versionNo].push(a);
                                }
                                return r;
                            }, Object.create(null))
                    ).flatMap((x: any) => x[1]
                        .sort((a: PolicySearchResult, b: PolicySearchResult) => typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type) || b.versionNo.localeCompare(a.versionNo))[0]
                    )
                        .sort((a, b) => b.versionNo.localeCompare(a.versionNo));
                    break;

                case SearchFilter.InvoiceNumber:
                    //Requirement: when you search by invoice number it should only return the specific result
                    relatedItems = selectedPolicy ? [selectedPolicy] : [];
                    break;


                case SearchFilter.ClientNumber:
                    relatedItems = Object.entries(
                        policySearchResults
                            //groups by x
                            .reduce(function (r, a) {
                                if (a.companyCode === selectedPolicy?.companyCode && a.branchCode === a.branchCode && a.coverNo === selectedPolicy?.coverNo && a.clientNo === selectedPolicy.clientNo) {
                                    r[a.companyCode + a.branchCode + a.coverNo + a.versionNo] = r[a.companyCode + a.branchCode + a.coverNo + a.versionNo] || [];
                                    r[a.companyCode + a.branchCode + a.coverNo + a.versionNo].push(a);
                                }
                                return r;
                            }, Object.create(null))
                    ).flatMap((x: any) => x[1]
                        .sort((a: PolicySearchResult, b: PolicySearchResult) => typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type) || b.versionNo.localeCompare(a.versionNo))[0]
                    )
                        .sort((a, b) => b.versionNo.localeCompare(a.versionNo));
                    break;

                case SearchFilter.ClientName:
                    relatedItems = Object.entries(
                        policySearchResults
                            //groups by x
                            .reduce(function (r, a) {
                                if (a.companyCode === selectedPolicy?.companyCode && a.branchCode === a.branchCode && a.coverNo === selectedPolicy?.coverNo && a.clientNo === selectedPolicy.clientNo) {
                                    r[a.companyCode + a.branchCode + a.coverNo + a.versionNo] = r[a.companyCode + a.branchCode + a.coverNo + a.versionNo] || [];
                                    r[a.companyCode + a.branchCode + a.coverNo + a.versionNo].push(a);
                                }
                                return r;
                            }, Object.create(null))
                    ).flatMap((x: any) => x[1]
                        .sort((a: PolicySearchResult, b: PolicySearchResult) => typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type) || b.versionNo.localeCompare(a.versionNo))[0]
                    )
                        .sort((a, b) => b.versionNo.localeCompare(a.versionNo));
                    break;

                default:
                    relatedItems = [...policySearchResults]
                        .filter(x => x.companyCode === selectedPolicy?.companyCode && x.branchCode === x.branchCode && x.coverNo === selectedPolicy?.coverNo)
                        .sort((a, b) => b.versionNo.localeCompare(a.versionNo));
                    break;
            }

            setVersions(relatedItems);
        }
        else {
            setVersions([]);
        }
    }, [policySearchResults, selectedResult, policySearchQuery]);

    const reportSearchTxt = reportSearchQuery.searchText ?? "";

    return (
        <>
            <div className="search-results container">
                {
                    (loadingPolicySearch || loadingReportSearch) ?
                        <Spinner />
                        :
                        (!!policySearchResults.length || !!reportSearchResults.length) &&
                        <>
                            <div className="collapsable">
                                <div className="header" onClick={() => setResultsOpen(!resultsOpen)}>
                                    <div className="header-text">Results</div>
                                    <div className="header-icon"><FontAwesomeIcon icon={resultsOpen ? faChevronUp : faChevronDown} /></div>
                                </div>
                                <Collapse isOpen={resultsOpen} className="content">
                                    <div className="results-table">
                                        {
                                            !!policySearchResults.length &&
                                            <PolicyResultsTable
                                                policySearchQuery={policySearchQuery}
                                                policySearchResults={policySearchResults}
                                                setSelectedResult={setSelectedResult}
                                                setSelectedPolicy={setSelectedPolicy}
                                                selectedResult={selectedResult}
                                                setVersionOpen={setVersionOpen}
                                            />
                                        }
                                        {
                                            !!reportSearchResults.length &&
                                            <ReportResultsTable
                                                reportSearchResults={reportSearchResults}
                                                reportSearchTxt={reportSearchTxt}
                                                selectedReport={selectedReport}
                                                setSelectedReport={setSelectedReport}
                                            />
                                        }
                                    </div>
                                </Collapse>
                            </div>
                            {!!policySearchResults.length && !!versions.length &&
                                <div className="collapsable">
                                    <div className="header" onClick={() => setVersionOpen(!versionOpen)}>
                                        <div className="header-text">Version</div>
                                        <div className="header-icon"><FontAwesomeIcon icon={versionOpen ? faChevronUp : faChevronDown} /></div>
                                    </div>
                                    <Collapse isOpen={versionOpen} className="content">
                                        <PolicyVersionResultsTable
                                            policySearchQuery={policySearchQuery}
                                            filteredPolicySearchResults={versions}
                                            selectedPolicy={selectedPolicy}
                                            selectedResult={selectedResult}
                                            setSelectedPolicy={setSelectedPolicy}
                                        />
                                    </Collapse>
                                </div>
                            }
                        </>
                }
                {
                    !loadingPolicySearch && !loadingReportSearch && ((!policySearchResults.length && props.lastSearchType === SearchType.Policy) || (!reportSearchResults.length && props.lastSearchType === SearchType.Report)) &&
                    <div className="no-results">
                        <h3>We couldn't find any matches</h3>
                        <span>Please check your details are correct and try again.</span>
                    </div>
                }

            </div>
        </>
    );
};

export default SearchResults;