import * as React from "react";
import { forwardRef } from "react";
import { FC } from "react";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { SkeletonBlock } from "skeleton-elements/react";
import "skeleton-elements/skeleton-elements.css";
import "./PolicyDetailsHeader.scss";

interface PolicyDetailsHeaderProps {
    data: any[];
    isLoading: boolean;
}

const PolicyDetailsHeader = (props: PolicyDetailsHeaderProps, ref: any) => {
    const history = useHistory();

    const policyData = props.data;
    const getFirstValidWebViewField = () =>
        policyData && !!policyData.length && policyData.find((x) => x?.WEB_RISKCODE !== "null" && x?.WEB_RISKCODE && x?.WEB_CLIENT);
    const firstValidField = getFirstValidWebViewField();

    const insurerBranch = firstValidField?.BRANCHCODE;
    const company = firstValidField?.COMPANYCODE;
    const clientNumber = firstValidField?.WEB_CLIENTNO;
    const effectiveDate = firstValidField?.WEB_EFFDATE;
    const renewalDate = firstValidField?.WEB_RENDATE;
    const expiryDate = firstValidField?.WEB_EXPIRYDATE;
    const clientName = firstValidField?.WEB_CLIENT;
    const riskCode = firstValidField?.RUD_RISKCODE;
    const coverReference = firstValidField?.WEB_COVERREFERENCE;
    const riskDescription = firstValidField?.RI_DESCRIPTION;

    const skeletonHeaderValue = (
        <SkeletonBlock width="100%" tag="div" height="28px" borderRadius="2px" effect="fade" />
    );

    return (
        <div ref={ref} className="policy-details-header sticky table-responsive">
            <div className="segment">
                <div className="title">Company/Branch/Client#</div>
                {props.isLoading ? (
                    skeletonHeaderValue
                ) : (
                    <div className="value">{`${company}/${insurerBranch}/${clientNumber}`}</div>
                )}
            </div>
            <div className="segment">
                <div className="title">Cov/Ver/SubCov</div>
                {props.isLoading ? (
                    skeletonHeaderValue
                ) : (
                    <div className="value">{coverReference}</div>
                )}
            </div>
            <div className="segment">
                <div className="title">Risk Code</div>
                {props.isLoading ? skeletonHeaderValue : <div className="value">{riskCode}</div>}
            </div>
            <div className="segment">
                <div className="title">Client Name</div>
                {props.isLoading ? skeletonHeaderValue : <div className="value">{clientName}</div>}
            </div>
            <div className="segment">
                <div className="title">Eff Date</div>
                {props.isLoading ? (
                    skeletonHeaderValue
                ) : (
                    <div className="value">
                        <Moment format="DD/MM/YYYY" date={effectiveDate} />
                    </div>
                )}
            </div>
            <div className="segment">
                <div className="title">Exp Date</div>
                {props.isLoading ? (
                    skeletonHeaderValue
                ) : (
                    <div className="value">
                        <Moment format="DD/MM/YYYY" date={expiryDate} />
                    </div>
                )}
            </div>
            <div className="segment">
                <div className="title">Ren Date</div>
                {props.isLoading ? (
                    skeletonHeaderValue
                ) : (
                    <div className="value">
                        <Moment format="DD/MM/YYYY" date={renewalDate} />
                    </div>
                )}
            </div>
            <div className="segment">
                <div className="title">Description</div>
                {props.isLoading ? skeletonHeaderValue : <div className="value">{riskDescription}</div>}
            </div>
            <div className="segment back-btn">
                <button className="btn" onClick={() => history.push("/search")}>Back</button>
            </div>
        </div>
    );
};

export default forwardRef(PolicyDetailsHeader);
