import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import {
    PublicClientApplication
} from "@azure/msal-browser";
import {
    MsalProvider
} from "@azure/msal-react";
import {
    Route, useHistory
} from 'react-router';
import {
    ToastContainer
} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CoverReferenceView from "./components/coverReferenceView/CoverReferenceView";
import {
    Layout
} from './components/Layout';
import {
    msalConfig
} from "./components/login/AuthConfig";
import Login from './components/login/Login';
import {
    GlobalProvider
} from "./store/globalState";
import Search from './pages/search/Search';
import Policy from "./pages/policy/Policy";
import './custom.scss';
import axios from 'axios';
import { AppSettings } from './types/AppSettings';
import Report from "./pages/report/Report";
import { ErrorBoundary } from 'react-error-boundary';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
const reactPlugin = new ReactPlugin();

const App: FC = () => {
    const history = useHistory();
    const [msalInstance, setMsalInstance] = useState<PublicClientApplication>();
    const [appSettings, setAppSettings] = useState<AppSettings>();

    //sets up auth provider using appsettings controller
    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(
                'api/AppSettings',
            );

            const data: AppSettings = result.data;
            setAppSettings(data);
        };
        fetchData();
    }, [setMsalInstance, setAppSettings]);

    //set the msal
    useEffect(() => {
        if (appSettings) {
            setMsalInstance(new PublicClientApplication(msalConfig(appSettings.clientId, appSettings.tenantId, appSettings.instance)));
            const appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: appSettings.instrumentationKey,
                    extensions: [reactPlugin],
                    extensionConfig: {
                        [reactPlugin.identifier]: { history: history }
                    }
                }
            });
            appInsights.loadAppInsights();
        }
    }, [appSettings, setMsalInstance, reactPlugin]);


    return (
        <>
            {
                !!msalInstance && !!appSettings &&
                <MsalProvider instance={msalInstance}>
                    <GlobalProvider msalInstance={msalInstance} appSettings={appSettings}>
                        <Layout>
                            <Route exact path='/' component={Login} />
                            <Route exact path='/search' component={Search} />
                            <Route exact path='/report' component={Report} />
                            <Route exact path='/cover-reference-view' component={CoverReferenceView} />
                            <ErrorBoundary
                                onError={(er: Error) => { reactPlugin.trackException({ error: er }); }}
                                key={"policyId"}
                                FallbackComponent={({ resetErrorBoundary }: { resetErrorBoundary: any }) => {
                                    history.listen(() => {
                                        resetErrorBoundary();
                                    });
                                    return (
                                        <div className="policy-error container">
                                            <div className="no-results">
                                                <h3>An Error has Occurred</h3>
                                                <div>This policy is unable to render. Please contact support.</div>
                                            </div>
                                        </div>
                                    );
                                }}
                            >
                                <Route exact path='/policy/:policyId' component={Policy} />
                            </ErrorBoundary>
                        </Layout>
                    </GlobalProvider>
                </MsalProvider>
            }

            <ToastContainer
                position="bottom-right"
                pauseOnFocusLoss
                autoClose={5000}
            />
        </>
    );
};

export default withAITracking(reactPlugin, App, undefined, "addinsight");
