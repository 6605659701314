import * as React from 'react';
import { FC, useState } from 'react';
import { AutoSizer, Table, Column, ScrollSync, ScrollEventData } from 'react-virtualized';
import Highlighter from 'react-highlight-words';
import { useEffect } from 'react';
import { ReportSearchResult } from '../../../types/ReportSearchResult';
import './Tables.scss';
import { Globals } from '../../../services/globalsService';
interface ReportResultsProps {
    reportSearchResults: ReportSearchResult[];
    reportSearchTxt: string;
    selectedReport: ReportSearchResult | undefined;
    setSelectedReport: (rpt: ReportSearchResult | undefined) => void;
}

const ReportResultsTable: FC<ReportResultsProps> = (props: ReportResultsProps) => {
    const [sortedReportResults, setSortedReportResults] = useState<ReportSearchResult[]>([]);

    const reportsRowCount = sortedReportResults.length;
    const reportsTableHeight = Math.min(Globals.MAX_HEIGHT, (reportsRowCount * Globals.ROW_HEIGHT) + Globals.HEADER_HEIGHT);
    const searchTxt = props.reportSearchTxt.trim();

    const reportRowClassName = (index: number) => {
        let className = "";
        if (index < 0) {
            className += "headerRow";
        }
        else {
            if (props.selectedReport && props.selectedReport === sortedReportResults[index]) {
                className += " selectedPolicy";
            }
            if (index % 2) {
                className += " evenRow";
            }
        }
        return className;
    };

    const onReportRowClick = (index: number) => {
        const res = sortedReportResults[index];

        if (res === props.selectedReport) {
            props.setSelectedReport(undefined);
        }
        else {
            props.setSelectedReport(res);
        }
    };

    useEffect(() => {
        //sorts reports by folder path z-a and then by file name a-z
        setSortedReportResults(
            props.reportSearchResults
                .sort((a: ReportSearchResult, b: ReportSearchResult) => b.folderPath.localeCompare(a.folderPath) || a.fileName.localeCompare(b.fileName)
                )
        );
    }, [props.reportSearchResults]);

    return (
        <div className="results-table">
            <ScrollSync>
                {({ onScroll, scrollTop }) => (
                    <AutoSizer disableHeight>
                        {({ width }) => (
                            <Table
                                height={reportsTableHeight}
                                width={width}
                                rowHeight={Globals.ROW_HEIGHT}
                                rowCount={reportsRowCount}
                                headerHeight={Globals.HEADER_HEIGHT}
                                scrollTop={scrollTop}
                                rowGetter={({ index }) => sortedReportResults[index]}
                                rowClassName={({ index }) => reportRowClassName(index)}
                                onRowClick={({ index }) => onReportRowClick(index)}
                                onScroll={onScroll as (info: ScrollEventData) => void}
                                overscanRowCount={30}
                            >
                                <Column
                                    width={100}
                                    label="Folder Path"
                                    dataKey="folderPath"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (searchTxt ?
                                            <Highlighter
                                                searchWords={[searchTxt]}
                                                autoEscape={true}
                                                textToHighlight={cellData}
                                            /> : <>{cellData}</>
                                        );
                                    }}
                                />
                                <Column
                                    width={100}
                                    label="File Name"
                                    dataKey="fileName"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (searchTxt ?
                                            <Highlighter
                                                searchWords={[searchTxt]}
                                                autoEscape={true}
                                                textToHighlight={cellData}
                                            /> : <>{cellData}</>
                                        );
                                    }}
                                />

                            </Table>
                        )}
                    </AutoSizer>
                )}
            </ScrollSync>
        </div>
    );
};

export default ReportResultsTable;