import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-virtualized/styles.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { debugContextDevtool } from 'react-context-devtool';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <App />
    </BrowserRouter>,
    rootElement);

//registerServiceWorker();

// Attach root container
debugContextDevtool(rootElement, {
    disable: process.env.NODE_ENV === "production"
});
