import * as React from 'react';
import { FC, useState, useEffect } from 'react';
import { AutoSizer, Table, Column, ScrollSync, ScrollEventData } from 'react-virtualized';
import Highlighter from 'react-highlight-words';
import { PolicySearchResult } from '../../../types/PolicySearchResult';
import { PolicySearchQuery, SearchFilter } from '../../../pages/search/Search';
import { sortByPreferredOrder } from '../../search/SearchResults';
import Moment from 'react-moment';
import './Tables.scss';
import { Globals } from '../../../services/globalsService';
import { getInvoiceWithNoPrefix } from '../../../utils/searchHelper';
import { truncateTimeFromDateTime } from '../../../utils/datetime';
import moment from 'moment';

interface PolicyResultsProps {
    policySearchQuery: PolicySearchQuery;
    policySearchResults: PolicySearchResult[];
    setVersionOpen: (open: boolean) => void;
    setSelectedPolicy: (policySearchResult: PolicySearchResult | undefined) => void;
    setSelectedResult: (policySearchResult: PolicySearchResult | undefined) => void;
    selectedResult: PolicySearchResult | undefined;
}

const PolicyResultsTable: FC<PolicyResultsProps> = (props: PolicyResultsProps) => {
    const { setSelectedResult, setVersionOpen, selectedResult, setSelectedPolicy, policySearchResults, policySearchQuery } = props;
    const [filteredPolicyResults, setFilteredPolicyResults] = useState<PolicySearchResult[]>([]);
    const policiesRowCount = filteredPolicyResults.length;
    const policiesTableHeight = Math.min(Globals.MAX_HEIGHT, (policiesRowCount * Globals.ROW_HEIGHT) + Globals.HEADER_HEIGHT);
    const policySearchTxt = policySearchQuery.searchText.trim() ?? "";

    const policyRowClassName = (index: number) => {
        let className = "";
        if (index < 0) {
            className += "headerRow";
        }
        else {
            if (selectedResult && selectedResult === filteredPolicyResults[index]) {
                className += " selectedPolicy";
            }
            if (index % 2) {
                className += " evenRow";
            }
        }
        return className;
    };

    const onPolicyRowClick = (index: number) => {
        const res = filteredPolicyResults[index];

        if (res === selectedResult) {
            setSelectedResult(undefined);
            setSelectedPolicy(undefined);
        }
        else {
            setSelectedResult(res);
            setSelectedPolicy(res);
            setVersionOpen(true);
        }
    };

    useEffect(() => {
        let sortedResults: PolicySearchResult[] = [];
        const typeOrder = ["PDV", "DebitInvoice", "CreditInvoice", "InsurerClosing", "FeeCredit", "FeeDebit", "DirectClientInvoice", "WebForm"];

        switch (policySearchQuery.filter) {
            case SearchFilter.PolicyNumber:
                sortedResults = Object.entries(
                    [...policySearchResults]
                        .filter(x => x.policyNo)
                        //groups by x
                        .reduce(function (r, a) {
                            if (a.policyNo) {
                                r[a.companyCode + a.branchCode + a.policyNo] = r[a.companyCode + a.branchCode + a.policyNo] || [];
                                r[a.companyCode + a.branchCode + a.policyNo].push(a);
                            }
                            return r;
                        }, Object.create(null))
                ).flatMap((x: any) => x[1]
                    .sort((a: PolicySearchResult, b: PolicySearchResult) => moment(b.effectiveDate).isSameOrBefore(moment(a.effectiveDate)) ? -1 : 1 || typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type) || b.versionNo.localeCompare(a.versionNo))[0]
                )
                    .sort((a: PolicySearchResult, b: PolicySearchResult) => {

                        if (a.policyNo > b.policyNo) {
                            return 1;
                        }
                        else if (a.policyNo < b.policyNo) {
                            return -1;
                        }
                        else {

                            return moment(b.effectiveDate).isSameOrBefore(moment(a.effectiveDate)) ? -1 : 1;
                        }
                    }
                    );
                break;

            case SearchFilter.InvoiceNumber:
                sortedResults = Object.entries(
                    [...policySearchResults]
                        //groups by x
                        .reduce(function (r, a) {
                            if (a.coverNo && a.invoiceNo) {
                                r[a.companyCode + a.branchCode + a.coverNo + a.invoiceNo] = r[a.companyCode + a.branchCode + a.coverNo + a.invoiceNo] || [];
                                r[a.companyCode + a.branchCode + a.coverNo + a.invoiceNo].push(a);
                            }
                            return r;
                        }, Object.create(null))
                ).flatMap((x: any) => x[1]
                    .sort((a: PolicySearchResult, b: PolicySearchResult) => moment(b.effectiveDate).isSameOrBefore(moment(a.effectiveDate)) ? -1 : 1 || typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type) || b.versionNo.localeCompare(a.versionNo))[0]
                )
                    .sort((a: PolicySearchResult, b: PolicySearchResult) => {

                        if (a.invoiceNo > b.invoiceNo) {
                            return 1;
                        }
                        else if (a.invoiceNo < b.invoiceNo) {
                            return -1;
                        }
                        else {

                            return moment(b.effectiveDate).isSameOrBefore(moment(a.effectiveDate)) ? -1 : 1;
                        }
                    }
                    );
                break;

            case SearchFilter.CoverNumber:
                sortedResults = Object.entries(
                    [...policySearchResults]
                        //groups by x
                        .reduce(function (r, a) {
                            if (a.coverNo) {
                                r[a.companyCode + a.branchCode + a.coverNo] = r[a.companyCode + a.branchCode + a.coverNo] || [];
                                r[a.companyCode + a.branchCode + a.coverNo].push(a);
                            }
                            return r;
                        }, Object.create(null))
                ).flatMap((x: any) => x[1]
                    .sort((a: PolicySearchResult, b: PolicySearchResult) => typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type) || b.versionNo.localeCompare(a.versionNo))[0]
                )
                    .sort((a: PolicySearchResult, b: PolicySearchResult) => {

                        if (a.coverNo > b.coverNo) {
                            return 1;
                        }
                        else if (a.coverNo < b.coverNo) {
                            return -1;
                        }
                        else {

                            return moment(b.effectiveDate).isSameOrBefore(moment(a.effectiveDate)) ? -1 : 1;
                        }
                    }
                    );
                break;

            case SearchFilter.ClientNumber:
                sortedResults = Object.entries(
                    [...policySearchResults]
                        //groups by x
                        .reduce(function (r, a) {
                            if (a.coverNo && a.clientNo) {
                                r[a.companyCode + a.branchCode + a.coverNo + a.clientNo] = r[a.companyCode + a.branchCode + a.coverNo + a.clientNo] || [];
                                r[a.companyCode + a.branchCode + a.coverNo + a.clientNo].push(a);
                            }
                            return r;
                        }, Object.create(null))
                ).flatMap((x: any) => x[1]
                    .sort((a: PolicySearchResult, b: PolicySearchResult) => typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type) || b.versionNo.localeCompare(a.versionNo))[0]
                )
                    .sort((a: PolicySearchResult, b: PolicySearchResult) => {

                        if (a.clientNo > b.clientNo) {
                            return 1;
                        }
                        else if (a.clientNo < b.clientNo) {
                            return -1;
                        }
                        else {

                            return moment(b.effectiveDate).isSameOrBefore(moment(a.effectiveDate)) ? -1 : 1;
                        }
                    }
                    );
                break;

            case SearchFilter.ClientName:
                sortedResults = Object.entries(
                    [...policySearchResults]
                        //groups by x
                        .reduce(function (r, a) {
                            if (a.coverNo && a.clientAbbrName) {
                                if (a.clientAbbrName) {
                                    r[a.companyCode + a.branchCode + a.coverNo + a.clientAbbrName] = r[a.companyCode + a.branchCode + a.coverNo + a.clientAbbrName] || [];
                                    r[a.companyCode + a.branchCode + a.coverNo + a.clientAbbrName].push(a);
                                }
                            }
                            return r;
                        }, Object.create(null))
                ).flatMap((x: any) => x[1]
                    .sort((a: PolicySearchResult, b: PolicySearchResult) => typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type) || b.versionNo.localeCompare(a.versionNo))[0]
                )
                    .sort((a: PolicySearchResult, b: PolicySearchResult) => {

                        if (a.clientAbbrName > b.clientAbbrName) {
                            return 1;
                        }
                        else if (a.clientAbbrName < b.clientAbbrName) {
                            return -1;
                        }
                        else {

                            return moment(b.effectiveDate).isSameOrBefore(moment(a.effectiveDate)) ? -1 : 1;
                        }
                    }
                    );
                break;

            case SearchFilter.None:
            default:
                sortedResults =
                    Object.entries([...policySearchResults]
                        .reduce(function (r, a) {
                            //groups by x
                            if (a.coverNo && a.type) {
                                r[a.companyCode + a.branchCode + a.coverNo + a.type] = r[a.companyCode + a.branchCode + a.coverNo + a.type] || [];
                                r[a.companyCode + a.branchCode + a.coverNo + a.type].push(a);
                            }
                            return r;
                        }, Object.create(null))
                    )
                        .flatMap((x: any) => x[1] //gets the latest version from the grouped results
                            .sort((a: PolicySearchResult, b: PolicySearchResult) => b.versionNo.localeCompare(a.versionNo))[0]
                        ).sort((a: PolicySearchResult, b: PolicySearchResult) => a.coverNo.localeCompare(b.coverNo) || a.type.localeCompare(b.type));
        }

        setFilteredPolicyResults(sortedResults);

    }, [policySearchResults, policySearchQuery]);


    useEffect(() => {
        //automatically selects the result if there is only one result returned
        if (filteredPolicyResults.length === 1 && selectedResult !== filteredPolicyResults[0]) {
            setSelectedResult(filteredPolicyResults[0]);
            setSelectedPolicy(filteredPolicyResults[0]);
            setVersionOpen(true);
        }
    }, [filteredPolicyResults, setSelectedResult, setVersionOpen, selectedResult, setSelectedPolicy]);

    return (
        <div className="results-table">
            <ScrollSync>
                {({ onScroll, scrollTop }) => (
                    <AutoSizer disableHeight>
                        {({ width }) => (
                            <Table
                                height={policiesTableHeight}
                                width={width}
                                rowHeight={Globals.ROW_HEIGHT}
                                rowCount={policiesRowCount}
                                headerHeight={Globals.HEADER_HEIGHT}
                                scrollTop={scrollTop}
                                rowGetter={({ index }) => filteredPolicyResults[index]}
                                rowClassName={({ index }) => policyRowClassName(index)}
                                onRowClick={({ index }) => onPolicyRowClick(index)}
                                onScroll={onScroll as (info: ScrollEventData) => void}
                                overscanRowCount={30}
                            >
                                <Column
                                    width={40}
                                    label="Company Code"
                                    dataKey="companyCode"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (<>{cellData}</>);
                                    }}
                                />
                                <Column
                                    width={40}
                                    label="Branch Code"
                                    dataKey="branchCode"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (<>{cellData}</>);
                                    }}
                                />
                                <Column
                                    width={100}
                                    label="Client Abbr Name"
                                    dataKey="clientAbbrName"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (((policySearchQuery?.filter === SearchFilter.ClientName)) ?
                                            <Highlighter
                                                searchWords={[policySearchTxt]}
                                                autoEscape={true}
                                                textToHighlight={cellData}
                                            /> : <>{cellData}</>
                                        );
                                    }}
                                />
                                <Column
                                    width={100}
                                    label="Insurer Abbr Name"
                                    dataKey="insurerAbbrName"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (<>{cellData}</>);
                                    }}
                                />
                                <Column
                                    width={100}
                                    label="Client #"
                                    dataKey="clientNo"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (((policySearchQuery?.filter === SearchFilter.ClientNumber)) ?
                                            <Highlighter
                                                searchWords={[policySearchTxt]}
                                                autoEscape={true}
                                                textToHighlight={cellData}
                                            /> : <>{cellData}</>
                                        );
                                    }}
                                />
                                <Column
                                    width={100}
                                    label="Cover #"
                                    dataKey="coverNo"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (((policySearchQuery?.filter === SearchFilter.CoverNumber)) ?
                                            <Highlighter
                                                searchWords={[policySearchTxt]}
                                                autoEscape={true}
                                                textToHighlight={cellData}
                                            /> : <>{cellData}</>
                                        );
                                    }}
                                />
                                <Column
                                    width={40}
                                    label="Version #"
                                    dataKey="versionNo"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (
                                            <>{cellData}</>
                                        );
                                    }}
                                />
                                <Column
                                    width={100}
                                    label="Invoice #"
                                    dataKey="invoiceNo"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        const searchTxt = getInvoiceWithNoPrefix(policySearchTxt);

                                        return (((policySearchQuery?.filter === SearchFilter.InvoiceNumber)) ?
                                            <Highlighter
                                                searchWords={[searchTxt]}
                                                autoEscape={true}
                                                textToHighlight={cellData}
                                            /> : <>{cellData}</>
                                        );
                                    }}
                                />
                                <Column
                                    width={100}
                                    label="Policy #"
                                    dataKey="policyNo"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (((policySearchQuery?.filter === SearchFilter.PolicyNumber)) ?
                                            <Highlighter
                                                searchWords={[policySearchTxt]}
                                                autoEscape={true}
                                                textToHighlight={cellData}
                                            /> : <>{cellData}</>
                                        );
                                    }}
                                />
                                <Column
                                    width={100}
                                    label="Risk"
                                    dataKey="riskCode"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (<>{cellData}</>);
                                    }}
                                />
                                <Column
                                    width={100}
                                    label="Effective Date"
                                    dataKey="effectiveDate"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (
                                            <Moment format={"DD/MM/YYYY"}>{truncateTimeFromDateTime(cellData)}</Moment>
                                        );
                                    }}
                                />
                                <Column
                                    width={100}
                                    label="Renewal Date"
                                    dataKey="renewalDate"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (
                                            <Moment format={"DD/MM/YYYY"}>{truncateTimeFromDateTime(cellData)}</Moment>
                                        );
                                    }}
                                />
                                <Column
                                    width={20}
                                    label="Transaction"
                                    dataKey="transactionCode"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (<>{cellData}</>);
                                    }}
                                />
                                <Column
                                    width={20}
                                    label="Current Version"

                                    dataKey="isCurrent"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (<>{cellData ? "Y" : "N"}</>);
                                    }}
                                />
                                {props?.policySearchQuery?.filter === SearchFilter.None &&
                                    <Column
                                        width={100}
                                        label="Type"
                                        dataKey="type"
                                        flexGrow={1}
                                        cellRenderer={({ cellData }) => {
                                            return (<>{cellData}</>);
                                        }}
                                    />
                                }

                            </Table>
                        )}
                    </AutoSizer>
                )}
            </ScrollSync>
        </div >
    );
};

export default PolicyResultsTable;