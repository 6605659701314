export interface AadGroupAccess {
    schemaCode: string;
    companyCode: string;
    branchCode: string;
    groupType: GroupType;
}

export enum GroupType {
    POLICY = 1,
    REPORT = 2,
}