
export const truncateTimeFromDateTime = (dateTime: string | Date) => {
    const dt = dateTime.toString();

    //This fixes issue where dates are rendering incorrectly due to timezone
    //E.g new Date('2021-04-06T00:00:00+13:00') renders the day before
    if (dt.includes("T")) {
        return new Date(dt.split("T")[0]);
    }
    else {
        return dateTime;
    }
};