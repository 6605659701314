import {
    GlobalAppState,
    GlobalAppActionType
} from "./globalState";


export default (state: GlobalAppState, action: GlobalAppActionType): GlobalAppState => {
    switch (action.type) {
        case "LOGIN_SUCCESSFUL":
            return {
                ...state,
                userAccessToken: action.accessToken,
                isUserLoggedIn: true
            };
        case "LOGIN_UNSUCCESSFUL":
            return {
                ...state,
            };
        case "LOGOUT_SUCCESSFUL":
            return {
                ...state,
                userAccessToken: undefined,
            };
        case "SET_ACCOUNT":
            return {
                ...state,
                userAccount: action.payload
            };
        case "SET_SELECTED_POLICY":
            return {
                ...state,
                selectedPolicy: action.payload,
            };
        case "SET_SELECTED_REPORT":
            return {
                ...state,
                selectedReport: action.payload,
            };
        case "RECEIVE_POLICY_SEARCH":
            //tries to cache search results to session storage
            try {
                sessionStorage.setItem("policySearchResults", JSON.stringify(action.payload));
            } catch (e) {
                console.warn("Session storage appears to be full!");
            }

            return {
                ...state,
                loadingPolicySearch: false,
                policySearchResults: action.payload
            };
        case "LOADING_POLICY_SEARCH":
            return {
                ...state,
                loadingPolicySearch: true
            };
        case "ERROR_POLICY_SEARCH":
            return {
                ...state,
                loadingPolicySearch: false
            };
        case "SET_SEARCH_QUERY":
            //tries to cache search query to session storage
            try {
                sessionStorage.setItem("policySearchQuery", JSON.stringify(action.payload));
            } catch (e) {
                console.warn("Session storage appears to be full!");
            }
            return {
                ...state,
                policySearchQuery: action.payload
            };
        case "RECEIVE_REPORT_SEARCH":
            //tries to cache search results to session storage
            try {
                sessionStorage.setItem("reportSearchResults", JSON.stringify(action.payload));
            } catch (e) {
                console.warn("Session storage appears to be full!");
            }

            return {
                ...state,
                loadingReportSearch: false,
                reportSearchResults: action.payload
            };
        case "LOADING_REPORT_SEARCH":
            return {
                ...state,
                loadingReportSearch: true
            };
        case "ERROR_REPORT_SEARCH":
            return {
                ...state,
                loadingReportSearch: false
            };
        case "SET_REPORT_SEARCH_QUERY":
            //caches search query to local storage
            try {
                sessionStorage.setItem("reportSearchQuery", JSON.stringify(action.payload));
            } catch (e) {
                console.warn("Session storage appears to be full!");
            }
            return {
                ...state,
                reportSearchQuery: action.payload
            };
        case "LOADING_REPORT":
            return {
                ...state,
                loadingReport: true
            };
        case "ERROR_REPORT":
            return {
                ...state,
                loadingReport: false
            };
        case "RECEIVE_REPORT":
            return {
                ...state,
                report: action.payload,
                loadingReport: false
            };
        case "LOADING_POLICY_DATA":
            return {
                ...state,
                loadingPolicyData: true
            };
        case "RECEIVE_POLICY_DATA":
            return {
                ...state,
                files: action.payload.files,
                webFormData: action.payload.webFormData,
                loadingPolicyData: false
            };
        case "ERROR_POLICY_DATA":
            return {
                ...state,
                loadingPolicyData: false
            };
        case "LOADING_USER_GROUPS":
            return {
                ...state,
                loadingUserGroups: true
            };
        case "RECEIVE_USER_GROUPS":
            return {
                ...state,
                userGroups: action.payload,
                loadingUserGroups: false
            };
        case "ERROR_USER_GROUPS":
            return {
                ...state,
                loadingUserGroups: false
            };
        default:
            return state;
    }
};
