import parse from "html-react-parser";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import FilesTableView from "../../components/filesTableView/FilesTableView";
import ProtectedPageAuthPrompt from "../../components/login/ProtectedPageAuthPrompt";
import PolicyDetailsHeader from "../../components/policyDetailsHeader/PolicyDetailsHeader";
import Spinner from "../../components/spinner/Spinner";
import { iconBeginning, iconLast, iconNext, iconPrevious } from "../../images";
import { GlobalAppState, GlobalContext } from "../../store/globalState";
import { WebFormData } from "../../types/PolicyData";
import { nestGroupsBy } from "../../utils/groupBy";
import { AddCommaIfNumber, buildColumn, buildInput, isSectionHeader } from "../../utils/policyHelper";
import useDimensions from "react-cool-dimensions";
import { ResizeObserver } from "@juggle/resize-observer";
import _ from "lodash";
import "./Policy.scss";

enum Tabs {
    FILES = "files",
}

type RouterProps = { policyId: string; };
interface PolicyProps extends RouteComponentProps<RouterProps> { }

type MappedWebFormData = {
    [key: string]: WebFormData[];
};

const Policy: FC<PolicyProps> = (props: PolicyProps) => {
    const policyId = props.match.params.policyId;

    const [activeTabKey, setActiveTabKey] = useState<string>();
    const [showNav, setShowNav] = useState<boolean>(false);
    const [groupedBySubCoverNo, setGroupedBySubCoverNo] = useState<MappedWebFormData>();
    const [groupedBySection, setGroupedBySection] = useState<MappedWebFormData>();
    const [groupedByRow, setGroupedByRow] = useState<MappedWebFormData>();
    const [websectionData, setWebsectionData] = useState<WebFormData[]>();
    const [selectedCoverReference, setSelectedCoverReference] = useState<string | null>(null);
    const [selectedItemIndex, setSelectedItem] = useState(0);
    const [maxItems, setMaxItems] = useState(0);
    const [maxCols, setMaxCols] = useState(1);
    const [firstElementFromActiveTab, setFirstElementFromActiveTab] = useState<WebFormData | null>(null);
    const [loadingError, setLoadingError] = useState("");

    const {
        axiosInterceptorLoaded,
        getPolicyData,
        files,
        downloadDataLakeFile,
        loadingPolicyData,
        webFormData,
        isUserLoggedIn,
    } = React.useContext<GlobalAppState>(GlobalContext);

    useEffect(() => {
        if (axiosInterceptorLoaded && policyId) {
            //get policy related data
            getPolicyData(policyId)
                .catch(ex => {
                    if (ex.message === "Request failed with status code 401") {
                        setLoadingError("You do not appear to be authenticated. Please ensure that popup's are not being blocked, refresh the page and login.");
                    }
                    else {
                        setLoadingError("There was an error loading this policy. Please ensure that this policy exists!");
                    }
                });
        }
    }, [policyId, axiosInterceptorLoaded]);

    //filters and groups the data by cover ref
    useEffect(() => {
        if (!!webFormData && !!webFormData.length) {
            //filter out unused data
            const filteredData = webFormData.filter(x =>
                x.RUD_INPUTTYPE !== "H" &&
                x.RUD_INPUTTYPE !== "X" &&
                !(x.RUD_INPUTTYPE === "S" && (!x?.PCD_CLAUSETEXT && !x?.PCH_FULLNAME))
            );

            //group the data by cover reference
            const groupedByCoverRef: MappedWebFormData = nestGroupsBy(filteredData, ['WEB_COVERREFERENCE']);
            setGroupedBySubCoverNo(groupedByCoverRef);
        }
        else {
            setGroupedBySubCoverNo({});
        }
    }, [webFormData, setActiveTabKey, setGroupedBySubCoverNo]);

    //sets the selected sub cover ref
    useEffect(() => {
        if (!selectedCoverReference && !!groupedBySubCoverNo && Object.keys(groupedBySubCoverNo).length === 1) {
            const subCoverNumberResults = Object.values(groupedBySubCoverNo)[0];
            const groupedBySection: MappedWebFormData = nestGroupsBy(subCoverNumberResults, ['CPF_SECSINO']);
            setGroupedBySection(groupedBySection);
        }
        else if (!!groupedBySubCoverNo && !!Object.keys(groupedBySubCoverNo).length && selectedCoverReference) {
            const subCoverNumberResults = groupedBySubCoverNo[selectedCoverReference];
            const groupedBySection: MappedWebFormData = nestGroupsBy(subCoverNumberResults, ['CPF_SECSINO']);
            setGroupedBySection(groupedBySection);
        }
        else {
            //clear out old results
            setGroupedBySection({});
            setGroupedByRow({});
            setWebsectionData([]);
            setSelectedCoverReference(null);
        }
    }, [groupedBySubCoverNo, selectedCoverReference, setGroupedBySection]);

    //set the active tab on load
    useEffect(() => {
        if (!!groupedBySection && !!Object.values(groupedBySection).length && !!webFormData?.length) {
            const firstTabGroup = Object.values(groupedBySection)[0];
            setActiveTabKey(firstTabGroup[0].CPF_SECSINO.toString());
        }
        else if (!webFormData?.length) {
            setActiveTabKey(Tabs.FILES);
        }
    }, [groupedBySection, webFormData]);

    useEffect(() => {
        if (activeTabKey === Tabs.FILES) {
            setMaxItems(0);
            setFirstElementFromActiveTab(null);
            setShowNav(false);
        }
        else if (groupedBySection && !!Object.keys(groupedBySection).length && activeTabKey && activeTabKey !== Tabs.FILES) {
            const activeGroup = groupedBySection[activeTabKey];
            if (activeGroup?.length) {

                //replace rud expressions
                const updatedActiveGroupData = activeGroup.map(x => {

                    if (x.RUD_EXPRESSION === "{IAC_POLICYNUMBER}" && !x.WEB_VALUE) {
                        x.WEB_VALUE = webFormData.find(x => !!x.IAC_POLICYNUMBER)?.IAC_POLICYNUMBER ?? "";
                    }
                    if (x.RUD_EXPRESSION === "{CLI_NAME}" && !x.WEB_VALUE) {
                        x.WEB_VALUE = webFormData.find(x => !!x.WEB_CLIENT)?.WEB_CLIENT ?? "";
                    }

                    return x;
                });


                const multiGroup: MappedWebFormData = nestGroupsBy(updatedActiveGroupData, ['WEB_SECNO']);
                const maxSections = Math.max(Object.keys(multiGroup).filter(x => x !== "undefined").length, 1);
                const firstElement = Object.values(multiGroup)[0][0];
                setMaxItems(maxSections);
                setFirstElementFromActiveTab(firstElement);

                const undefinedItems = Object.entries(multiGroup).find(y => y[0] === "undefined");
                const otherItems = Object.entries(multiGroup).filter(y => y[0] !== "undefined");

                //determine if the use should be able to cycle through multiple items
                const allSameLength = otherItems.length > 1 &&
                    (otherItems.filter(a => a[1].length === otherItems[0][1].length).length === otherItems.length || maxSections > 1) &&
                    firstElement.CPF_HASMULTITEMS === 1;

                setShowNav(allSameLength);

                //combine websections
                let combined: WebFormData[] = [];
                if (allSameLength) {
                    if (selectedItemIndex >= otherItems.length) {
                        combined = combined.concat(otherItems[0] ? otherItems[0][1] : []);
                        combined = combined.concat(undefinedItems ? undefinedItems[1] : []);
                    }
                    else {
                        combined = combined.concat(otherItems[selectedItemIndex] ? otherItems[selectedItemIndex][1] : []);
                        combined = combined.concat(undefinedItems ? undefinedItems[1] : []);
                    }
                }
                else {
                    combined = activeGroup;
                }

                const maxColCount = Math.max(...combined.map((o) => o.RUD_COLNO));
                const groupedByRows: MappedWebFormData = nestGroupsBy(combined, ['RUD_ROWNO']);
                setWebsectionData(combined);
                setMaxCols(maxColCount);
                setGroupedByRow(groupedByRows);
            }
        }
        else {
            setShowNav(false);
        }
    }, [activeTabKey, setMaxItems, groupedBySection, setFirstElementFromActiveTab, setGroupedByRow, selectedItemIndex]);

    const toggle = (tab: string | number) => {
        if (activeTabKey !== tab) setActiveTabKey(tab.toString());
    };

    const handleFirstPageBtnClick = () => setSelectedItem(0);
    const handlePreviousPageBtnClick = () => selectedItemIndex > 0 && setSelectedItem(selectedItemIndex - 1);
    const handleNextPageBtnClick = () => selectedItemIndex < maxItems - 1 && setSelectedItem(selectedItemIndex + 1);
    const handleLastPageBtnClick = () => selectedItemIndex < maxItems - 1 && setSelectedItem(maxItems - 1);

    const renderHeader = (ref: any) => {

        //is not multi risk
        if (!!groupedBySubCoverNo && Object.keys(groupedBySubCoverNo).length === 1) {
            return <PolicyDetailsHeader ref={ref} data={webFormData} isLoading={loadingPolicyData} />;
        }
        //is multi risk
        else if (!!groupedBySubCoverNo && Object.keys(groupedBySubCoverNo).length >= 2) {
            if (selectedCoverReference) {
                return <PolicyDetailsHeader ref={ref} data={groupedBySubCoverNo[selectedCoverReference]} isLoading={loadingPolicyData} />;
            }
            else {
                return <></>;
            }
        }
        else {
            return <PolicyDetailsHeader data={webFormData} isLoading={loadingPolicyData} />;
        }
    };

    //reports the size of the headerbar so that we can offset the tabs 
    const { height: headerHeight, observe } = useDimensions<HTMLDivElement>({ polyfill: ResizeObserver, useBorderBoxSize: true });

    return (
        !isUserLoggedIn ?
            <ProtectedPageAuthPrompt />
            :
            <>
                {

                    loadingError ?
                        <div className="policy-error container">
                            <div className="no-results">
                                <h3>An Error has Occurred</h3>
                                <span>{loadingError}</span>
                            </div>
                        </div> :
                        <>
                            {!!webFormData?.length && renderHeader(observe)}
                            <div id="policy-tabs" className="container">
                                {loadingPolicyData ?
                                    <Spinner /> :
                                    <>
                                        {
                                            !!groupedBySubCoverNo && Object.keys(groupedBySubCoverNo).length >= 2 &&
                                            <div>
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>SubCov</th>
                                                            <th>Sub Risk Code</th>
                                                            <th>Sub Risk Description</th>
                                                            <th>Insurer Code/Branch</th>
                                                            <th>Eff. Date</th>
                                                            <th>Exp. Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            Object.values(groupedBySubCoverNo)
                                                                .map((r: WebFormData[], trIndex: number) => {
                                                                    const firstVal = r.find((x) => x?.WEB_RISKCODE !== "null" && x?.WEB_RISKCODE !== null) ?? r[0];
                                                                    const insurerBranch = r.find(y => y.WEB_CAPTIONNAME === "INSURERBRANCH");

                                                                    const subCoverSplit = firstVal.WEB_COVERREFERENCE.split("/");
                                                                    return (
                                                                        <tr key={trIndex}
                                                                            className={"cursor-pointer" + (firstVal.WEB_COVERREFERENCE.trim() === selectedCoverReference?.trim() ? " selected" : "")}
                                                                            onClick={() => {
                                                                                setActiveTabKey(firstVal.CPF_SECSINO.toString());
                                                                                setSelectedCoverReference(firstVal.WEB_COVERREFERENCE.trim());
                                                                            }}>
                                                                            <td>{subCoverSplit[subCoverSplit.length - 1]}</td>
                                                                            <td>{firstVal.RUD_RISKCODE}</td>
                                                                            <td>{firstVal.RI_DESCRIPTION}</td>
                                                                            <td>{insurerBranch?.WEB_VALUE}</td>
                                                                            <td>{firstVal.WEB_EFFDATE}</td>
                                                                            <td>{firstVal.WEB_EXPIRYDATE}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        }

                                        <Nav tabs className={(headerHeight ? "sticky" : "")} style={{ top: headerHeight ?? "unset" }}>
                                            {
                                                groupedBySection &&
                                                Object.values(groupedBySection).map((x: WebFormData[], i: number) => {
                                                    const firstElement = x[0];

                                                    return (
                                                        <NavItem key={i}>
                                                            <NavLink
                                                                onClick={() => toggle(firstElement.CPF_SECSINO)}
                                                                className={activeTabKey === firstElement.CPF_SECSINO.toString() ? "active" : ""}
                                                            >{firstElement.CPF_SECNAME}{firstElement.CPF_HASMULTITEMS === 1 && <span className="multi-risk">*</span>}</NavLink>
                                                        </NavItem>
                                                    );
                                                })
                                            }
                                            <NavItem>
                                                <NavLink
                                                    onClick={() => toggle(Tabs.FILES)}
                                                    className={activeTabKey === Tabs.FILES ? "active" : ""}
                                                >Files</NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent className="container" activeTab={activeTabKey}>
                                            {
                                                groupedBySection && activeTabKey && firstElementFromActiveTab &&
                                                <TabPane tabId={firstElementFromActiveTab?.CPF_SECSINO.toString()}>
                                                    <Row>
                                                        <Col sm="12">
                                                            {
                                                                websectionData && groupedByRow &&
                                                                <>
                                                                    {
                                                                        Object.values(groupedByRow).map((x: WebFormData[], i: number) => {
                                                                            const isOnlyItemInRow = x.length === 1;
                                                                            const hasSectionHeader = x.some((z) => isSectionHeader(z));
                                                                            const isSubSectionHeader = !!x[0].SSS_SUBSECNAME && [...websectionData.filter((z) => z.RUD_SUBSECNO === x[0]?.RUD_SUBSECNO)].sort((a, b) => a.RUD_ROWNO.toString().localeCompare(b.RUD_ROWNO.toString()))[0] === x[0];
                                                                            const isPch = !!x[0].PCH_FULLNAME;
                                                                            const isTable = x[0]?.WEB_SSNO && /\s/g.test(x[0]?.WEB_SSNO) && !isNaN(x[0]?.WEB_SSNO.trim() as any);
                                                                            const subsectionData = [...websectionData.filter((z) => z.RUD_SUBSECNO === x[0]?.RUD_SUBSECNO)].sort((a, b) => a.RUD_ROWNO.toString().localeCompare(b.RUD_ROWNO.toString()));
                                                                            const isFirstTableVal = !!isTable && subsectionData[0] === x[0];
                                                                            //const maxColsperRow = Math.max.apply(Math, x.map((o) => o.RUD_COLNO));
                                                                            const pch = webFormData?.find(z => z.WEB_CAPTIONNAME === x[0].RUD_CAPTION);

                                                                            const pchData: MappedWebFormData = nestGroupsBy(x, ['WEB_SECNO', 'WEB_SUBNO']);

                                                                            //is a table
                                                                            if (isTable && isFirstTableVal) {
                                                                                const tableRows: MappedWebFormData = nestGroupsBy(subsectionData, ['WEB_SSNO']);
                                                                                const undefinedItems = Object.entries(tableRows).find(y => y[0] === "undefined")?.[1] ?? [];
                                                                                return (
                                                                                    <div key={i}>
                                                                                        {
                                                                                            isSubSectionHeader &&
                                                                                            <Row
                                                                                                sm={12}
                                                                                                className="policy-row header"
                                                                                            >
                                                                                                <div className={"display col-12 sub-heading"}>
                                                                                                    {x[0].SSS_SUBSECNAME && parse(x[0].SSS_SUBSECNAME)}
                                                                                                </div>
                                                                                            </Row>
                                                                                        }
                                                                                        <Row
                                                                                            sm={12}
                                                                                            className="policy-row"
                                                                                        >
                                                                                            <Col
                                                                                                sm={12}
                                                                                                className="policy-col"
                                                                                            >
                                                                                                <div className="table-responsive">
                                                                                                    <table className="table">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>Item No</th>
                                                                                                                {
                                                                                                                    [...Object.values(Object.values(tableRows)[0].concat(undefinedItems))]
                                                                                                                        .sort((a, b) => parseInt(a?.WEB_SSSNO?.trim()) - parseInt(b?.WEB_SSSNO?.trim()))
                                                                                                                        .map((r: WebFormData, thIndex: number) => {
                                                                                                                            return <th key={thIndex}>{parse(r.RUD_DESCRIPTION)}</th>;
                                                                                                                        })
                                                                                                                }
                                                                                                                <th>I</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                [...Object.entries(tableRows)]
                                                                                                                    .filter(y => y[0] !== "undefined")
                                                                                                                    .map((tr, trIndex: number) => {
                                                                                                                        return (
                                                                                                                            <tr key={trIndex}>
                                                                                                                                <td>{trIndex + 1}</td>
                                                                                                                                {
                                                                                                                                    [...Object.values(tr[1])]
                                                                                                                                        .concat(undefinedItems)
                                                                                                                                        .sort((a, b) => parseInt(a?.WEB_SSSNO?.trim()) - parseInt(b?.WEB_SSSNO?.trim()))
                                                                                                                                        .map((td: WebFormData, tdIndex: number) => {
                                                                                                                                            let val = "";
                                                                                                                                            if (td?.RUD_INPUTTYPE === "C" && td?.RUD_DROPDOWNSTYLE === "DO") {
                                                                                                                                                val = td?.VAC_VALUE;
                                                                                                                                            }
                                                                                                                                            else if (td.RUD_DATATYPE === "C2" || td.RUD_DATATYPE === "C3" || (td.RUD_DATATYPE === "C")) {
                                                                                                                                                val = AddCommaIfNumber(td.WEB_VALUE);
                                                                                                                                            }
                                                                                                                                            else if (!td.WEB_VALUE && !!td.RUD_DEFAULT) {
                                                                                                                                                val = td.RUD_DEFAULT;
                                                                                                                                            }
                                                                                                                                            else {
                                                                                                                                                val = td.WEB_VALUE;
                                                                                                                                            }

                                                                                                                                            return (
                                                                                                                                                <td key={tdIndex}>{val}</td>
                                                                                                                                            );
                                                                                                                                        })
                                                                                                                                }
                                                                                                                                <td>{tr[1][0]?.WEB_ACTION?.charAt(0)}</td>
                                                                                                                            </tr>
                                                                                                                        );
                                                                                                                    })
                                                                                                            }
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                            else if (!isFirstTableVal && isTable) {
                                                                                return <div key={i}></div>;
                                                                            }
                                                                            return (
                                                                                <Row
                                                                                    key={i}
                                                                                    className={
                                                                                        "policy-row" +
                                                                                        ((hasSectionHeader) ? " header" : "") +
                                                                                        (isPch && !!pch?.WEB_VALUE && !!pch?.PCH_SHOWKEY && pch?.WEB_VALUE === pch?.PCH_SHOWKEY ? " pch" : "")
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        isSubSectionHeader &&
                                                                                        <Col
                                                                                            sm={12}
                                                                                            className="policy-col mb-3"
                                                                                        >
                                                                                            <div className={"display col-12 sub-heading"}>
                                                                                                {x[0].SSS_SUBSECNAME && parse(x[0].SSS_SUBSECNAME)}
                                                                                            </div>
                                                                                        </Col>
                                                                                    }
                                                                                    {
                                                                                        isPch && !!pch &&
                                                                                        pch?.PCH_HIDEFULLNAME === 0 && ((!!pch.WEB_VALUE || pch.PCH_GROUPTYPE === "SA") || (!!pch.WEB_VALUE || pch.PCH_GROUPTYPE === "SH")) &&
                                                                                        (pch.WEB_VALUE !== pch.PCH_HIDEKEY || pch.PCH_GROUPTYPE !== "SC") &&
                                                                                        <Col
                                                                                            sm={12}
                                                                                            className="policy-col mb-1 pch-block"
                                                                                        >
                                                                                            <div className={"display" + ((pch.WEB_VALUE === pch.PCH_SHOWKEY || pch.WEB_VALUE === pch.PCH_HIDEKEY || pch.PCH_INPUTTYPE === "C") ? " heading" : "") + (pch.PCH_INPUTTYPE === "R" || pch.PCH_INPUTTYPE === "C" ? " col-6" : " col-12")}>
                                                                                                {pch.PCH_FULLNAME && parse(pch.PCH_FULLNAME)}
                                                                                            </div>
                                                                                            {
                                                                                                pch.PCH_INPUTTYPE === "R" &&
                                                                                                <div className={"display col-6"}>
                                                                                                    {
                                                                                                        pch.PCH_INPUTTYPE === "R" &&
                                                                                                        <div className="d-flex">
                                                                                                            <div className="option radio-right pr-2" >
                                                                                                                <input
                                                                                                                    type="radio"
                                                                                                                    name={pch.RUD_CAPTION}
                                                                                                                    id={pch.RUD_CAPTION + pch.PCH_SHOWKEY}
                                                                                                                    defaultChecked={pch.PCH_SHOWKEY?.toLocaleLowerCase() === (pch.WEB_VALUE?.toLocaleLowerCase() ?? pch.PCH_DEFAULT?.toLocaleLowerCase() ?? pch.RUD_DEFAULT?.toLocaleLowerCase())}
                                                                                                                />
                                                                                                                <label htmlFor="none">{pch.PCH_SHOWDESCRIPTION}</label>
                                                                                                            </div>
                                                                                                            <div className="option radio-right" >
                                                                                                                <input
                                                                                                                    type="radio"
                                                                                                                    name={pch.RUD_CAPTION}
                                                                                                                    id={pch.RUD_CAPTION + pch.PCH_HIDEKEY}
                                                                                                                    defaultChecked={pch.PCH_HIDEKEY?.toLocaleLowerCase() === (pch.WEB_VALUE?.toLocaleLowerCase() ?? pch.PCH_DEFAULT?.toLocaleLowerCase() ?? pch.RUD_DEFAULT?.toLocaleLowerCase())}
                                                                                                                />
                                                                                                                <label htmlFor="none">{pch.PCH_HIDEDESCRIPTION}</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                pch.PCH_INPUTTYPE === "C" &&
                                                                                                <div className={"display col-6"}>
                                                                                                    <div className={"value"}>
                                                                                                        {pch.WEB_VALUE}
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </Col>
                                                                                    }
                                                                                    {
                                                                                        isPch && Object.entries(Object.values(pchData ?? [])[0]).filter(x => x[0] !== "*")
                                                                                            .map((z: any, k: number) => {
                                                                                                const key: number = z[0];
                                                                                                const data: WebFormData[] = z[1];

                                                                                                return (
                                                                                                    <>
                                                                                                        {
                                                                                                            data.sort((a, b) => (a?.PCD_SEQUENCENO ?? 0) - (b?.PCD_SEQUENCENO ?? 0)) //orders by PCD_SEQUENCENO
                                                                                                                .map((y: WebFormData, j: number) => {

                                                                                                                    return (
                                                                                                                        <Col
                                                                                                                            key={j}
                                                                                                                            sm={12}
                                                                                                                            className="policy-col"
                                                                                                                        >
                                                                                                                            <div className={"display col-6"}>
                                                                                                                                {y?.PCD_CLAUSETEXT && parse(y.PCD_CLAUSETEXT)}
                                                                                                                            </div>
                                                                                                                            {
                                                                                                                                !!y?.PCD_CLAUSETEXT &&
                                                                                                                                <div className={"display col-6"}>
                                                                                                                                    {buildInput(y, true)}
                                                                                                                                </div>
                                                                                                                            }
                                                                                                                        </Col>
                                                                                                                    );
                                                                                                                })
                                                                                                        }
                                                                                                        {
                                                                                                            Object.entries(Object.values(pchData ?? [])[0]).filter(x => x[0] !== "*").length > 1 &&
                                                                                                            <div className="d-flex col-12 justify-content-end"><strong>Item No:{key}</strong></div>
                                                                                                        }
                                                                                                    </>
                                                                                                );
                                                                                            })
                                                                                    }
                                                                                    {
                                                                                        !isPch &&
                                                                                        Array(maxCols) //creates an array [1...maxCols]
                                                                                            .fill(0)
                                                                                            .map((_, k) => k + 1)
                                                                                            .map((z: number) => {
                                                                                                const wfd = x
                                                                                                    .sort((a: WebFormData, b: WebFormData) => b?.WEB_SSSNO?.trim().localeCompare(a?.WEB_SSSNO?.trim())) //if 2 items have the same row/col take the item with the greatest WEB_SSSNO
                                                                                                    .find((q: WebFormData) => q.RUD_COLNO.toString() === z.toString());
                                                                                                const colSize = 12 / maxCols;
                                                                                                if (wfd) {
                                                                                                    return (
                                                                                                        <Col
                                                                                                            key={z}
                                                                                                            sm={wfd.RUD_INPUTTYPE === "D" || isOnlyItemInRow ? 12 : colSize}
                                                                                                            className="policy-col"
                                                                                                        >
                                                                                                            {buildColumn(wfd)}
                                                                                                        </Col>
                                                                                                    );
                                                                                                } else {
                                                                                                    if (x.length > 1) {
                                                                                                        return <Col key={z} sm={colSize}></Col>;
                                                                                                    }
                                                                                                    else return <></>;
                                                                                                }
                                                                                            })
                                                                                    }
                                                                                </Row>
                                                                            );
                                                                        })
                                                                    }
                                                                </>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            }

                                            <TabPane tabId={Tabs.FILES}>
                                                <Row>
                                                    <Col sm="12">
                                                        <FilesTableView
                                                            files={files}
                                                            fileClickCallBack={downloadDataLakeFile}
                                                            isLoading={loadingPolicyData}
                                                        />
                                                    </Col>
                                                </Row>
                                            </TabPane>

                                        </TabContent>
                                    </>
                                }
                            </div>
                            {
                                showNav &&
                                <div className="multi-risk-bar">
                                    <div className="container">
                                        <button onClick={handleFirstPageBtnClick} disabled={selectedItemIndex === 0}><img src={iconBeginning} alt="Go to first page" /></button>
                                        <button onClick={handlePreviousPageBtnClick} disabled={selectedItemIndex === 0}><img src={iconPrevious} alt="Go to previous page" /></button>
                                        <input type="text" value={`Page ${selectedItemIndex + 1}`} onChange={() => { }} readOnly />
                                        <button onClick={handleNextPageBtnClick} disabled={selectedItemIndex === maxItems - 1}><img src={iconNext} alt="Go to next page" /></button>
                                        <button onClick={handleLastPageBtnClick} disabled={selectedItemIndex === maxItems - 1}><img src={iconLast} alt="Go to last page" /></button>
                                    </div>
                                </div>
                            }
                        </>
                }
            </>
    );
};

export default Policy;