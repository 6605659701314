import { Redirect } from 'react-router-dom';
import
React, {
    FC
} from 'react';


import {
    RouteComponentProps,
    withRouter,
} from 'react-router-dom';
import {
    GlobalAppState,
    GlobalContext,
} from '../../store/globalState';
import './ProtectedPageAuthPrompt.scss';

interface ProtectedPageAuthPromptProps extends RouteComponentProps<any> {
}

const ProtectedPageAuthPrompt: FC<ProtectedPageAuthPromptProps> = () => {
    const {
        isUserLoggedIn,
        inheritedMsalInstance,
    } = React.useContext<GlobalAppState>(GlobalContext);

    const accounts = inheritedMsalInstance?.getAllAccounts();

    return (
        <>
            {
                !isUserLoggedIn &&
                inheritedMsalInstance &&
                accounts?.length === 0 &&
                <>
                    <p>
                        You must login to view this page
                    </p>

                    <Redirect to="/" />
                </>
            }
        </>
    );
};

export default withRouter(ProtectedPageAuthPrompt);
