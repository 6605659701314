import * as  React from "react";
import { WebFormData } from "../types/PolicyData";
import TextareaAutosize from 'react-textarea-autosize';
import parse from "html-react-parser";

export const isSectionHeader = (data: WebFormData) =>
    data.RUD_DESCRIPTION === "Display" && (data.RUD_DEFAULT?.toString()?.startsWith("<u><b>") || data.RUD_DEFAULT?.toString()?.startsWith("<b>"));

export const setAlignment = (alignment: string) => {
    switch (alignment) {
        case "L":
            return { textAlign: "left" } as React.CSSProperties;
        case "R":
            return { textAlign: "right" } as React.CSSProperties;
        default:
            return { textAlign: "left" } as React.CSSProperties;
    }
};

export const AddCommaIfNumber = (val: string) => {
    if (!!val && !isNaN(val as any)) {
        const vals = val.split(".");
        vals[0] = vals[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return vals.join(".");
    }
    else {
        return val;
    }
};

export const EnsureTwoDecimalPlaces = (val: string) => {
    if (!!val && !isNaN(val as any)) {
        return parseFloat(val).toFixed(2).toString();
    }
    else {
        return val;
    }
};

export const RemoveExcessDecimalPlaces = (val: string) => {
    return (+(Math.round(parseFloat(val + "e+2")) + "e-2")).toString();
};

export const buildInput = (webFormData: WebFormData, pcd = false) => {
    let val = "";

    const inputType = pcd ? webFormData.PCD_INPUTTYPE : webFormData.RUD_INPUTTYPE;
    const dataType = pcd ? webFormData.PCD_DATATYPE : webFormData.RUD_DATATYPE;
    const options = pcd ? webFormData.PCD_OPTIONS : webFormData.RUD_OPTIONS;
    const def = pcd ? webFormData.PCD_DEFAULT : webFormData.RUD_DEFAULT;

    switch (inputType) {
        case "T": //textbox
            if (webFormData.WEB_VALUE === "null" || !webFormData.WEB_VALUE) {
                val = "-";
            }
            else if (dataType === "C2" || dataType === "C3") {
                val = AddCommaIfNumber(EnsureTwoDecimalPlaces(webFormData.WEB_VALUE));
            }
            else if (options === "-999999999~999999999" || options === "0~999999999") {
                val = AddCommaIfNumber(RemoveExcessDecimalPlaces(webFormData.WEB_VALUE));
            }
            else {
                val = webFormData.WEB_VALUE;
            }

            return (<div>{val}</div>);
        case "P": //date textbox
            return (
                <div>
                    {webFormData.WEB_VALUE !== "null" ? webFormData.WEB_VALUE ?? "-" : "-"}
                </div>
            );
        case "A": //textarea
            return (
                <TextareaAutosize
                    value={webFormData.WEB_VALUE !== "null" ? webFormData?.WEB_VALUE ?? webFormData?.RUD_DEFAULT ?? "" : ""}
                    minRows={4}
                    readOnly
                />
            );
        case "C": //dropdown
            switch (webFormData.RUD_DROPDOWNSTYLE) {
                case "DO":
                    return (
                        <select defaultValue={webFormData.VAC_VALUE} >
                            <option value={webFormData.VAC_VALUE}> {webFormData?.VAC_VALUE ?? "<< Select a value >>"
                            }</option>
                        </select>
                    );
                case "CD":
                    return (
                        <select defaultValue={webFormData.WEB_VALUE} >
                            <option value={webFormData.WEB_VALUE}> {webFormData?.WEB_VALUE ?? webFormData?.RUD_DEFAULT ?? "<< Select a value >>"}</option>
                        </select>
                    );
                case "CO":
                    return (
                        <select defaultValue={webFormData.VAC_VALUE} >
                            <option value={webFormData.VAC_VALUE}> {webFormData?.VAC_VALUE ?? "<< Select a value >>"}</option>
                        </select>
                    );
                default:
                    if (pcd) {
                        if (webFormData.PCH_GROUPTYPE === "SH") {
                            return (webFormData.WEB_VALUE);
                        }

                        return (
                            <select defaultValue={webFormData.WEB_VALUE} >
                                <option value={webFormData.WEB_VALUE}> {webFormData.PCH_GROUPTYPE === "SC" ? webFormData.VAC_VALUE : webFormData.WEB_VALUE}</option>
                            </select>
                        );
                    }
                    else {
                        return (
                            <select defaultValue={webFormData.WEB_VALUE} >
                                <option value={webFormData.WEB_VALUE}> {webFormData?.WEB_VALUE ?? def ?? "<< Select a value >>"}</option>
                            </select>
                        );
                    }
            }
        case "R": {
            //radio buttons
            const radioOptions = options?.split("`")
                .map((x: string) => {
                    const subSplit = x.split("~");
                    return subSplit[subSplit.length - 1];
                })
                .slice(0, -1);

            const radioOptionValues = options?.split("`").map((x: string) => {
                const subSplit = x.split("~");
                return subSplit[0];
            }).splice(1);

            if (radioOptions !== undefined) {
                return (
                    <div className="filters d-flex" >
                        {
                            radioOptions.map((x: string, i: number) => {
                                const id = `${webFormData.WEB_COVERREFERENCE}_${webFormData.CPF_SECSINO}_${webFormData.RUD_CAPTION}_${x}`;
                                return (
                                    <div key={id} className="option radio-right" >
                                        <input
                                            type="radio"
                                            name={`${webFormData.RUD_CAPTION}_${webFormData.WEB_SSNO}_${webFormData.WEB_SSSNO}`}
                                            id={id}
                                            defaultChecked={
                                                (webFormData.WEB_VALUE?.toLocaleLowerCase() === radioOptionValues[i]?.toLocaleLowerCase()) ||
                                                (!webFormData.WEB_VALUE && webFormData.RUD_DEFAULT === radioOptionValues[i]) ||
                                                (!webFormData.WEB_VALUE && radioOptionValues[i] === "") ||
                                                (!webFormData.WEB_VALUE && !def && i === 0)
                                            }
                                        />
                                        <label htmlFor="none">{x}</label>
                                    </div>
                                );
                            })
                        }
                    </div>
                );
            } else {
                //unable to parse - returning as a string
                return <div>{webFormData.WEB_VALUE} </div>;
            }
        }
        default:
            return <div>{webFormData.WEB_VALUE} </div>;
    }
};


export const buildColumn = (webFormData: WebFormData) => {

    const hasSectionHeader = isSectionHeader(webFormData);

    if (webFormData.RUD_DESCRIPTION === "Display" || webFormData.RUD_INPUTTYPE === "D") {
        return (
            <div className={"display col-12" + (hasSectionHeader ? " heading" : "")}>
                {webFormData?.RUD_DEFAULT && parse(webFormData?.RUD_DEFAULT)}
            </div>
        );
    } else {
        return (
            <>
                <div
                    style={setAlignment(webFormData?.RUD_ALIGNMENT ?? "")}
                    className="title col-6"
                    title={webFormData.RUD_LONGDESCRIPTION}>{webFormData?.RUD_DESCRIPTION && parse(webFormData?.RUD_DESCRIPTION)}{webFormData.RUD_MANDATORY.toString() === "-1" && <span className="required">*</span>}</div>
                <div className="value col-6">{buildInput(webFormData)}</div>
            </>
        );
    }
};