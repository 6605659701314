import {
    AccountInfo,
    Configuration,
    PopupRequest,
    SilentRequest
} from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig = (clientId: string, authority: string, instance: string): Configuration => <Configuration>{
    auth: {
        // Following these instructions: https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-app-registration#create-the-app-registration
        // Make sure to use the Application id of the MANAGED object and this should match what is used in
        // the GUID part of the audience field in the backend AzureAd configuration
        clientId: clientId,
        authority: `${instance.endsWith("/") ? instance : instance + "/"}${authority}`
    },
    cache: { cacheLocation: 'localStorage' }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = (scopes: string[]): PopupRequest => <PopupRequest>{
    // https://docs.microsoft.com/en-us/azure/active-directory/develop/quickstart-configure-app-expose-web-apis#add-a-scope
    scopes: scopes,
    prompt: "select_account"
};

export const buildWebApiTokenRequest = (account: AccountInfo | undefined, scopes: string[]): PopupRequest | SilentRequest => {
    return {
        // https://docs.microsoft.com/en-us/azure/active-directory/develop/quickstart-configure-app-expose-web-apis#add-a-scope
        scopes: scopes,
        account: account,
        redirectUri: window.location.origin
    };
};
