import * as React from 'react';
import { Component } from 'react';
import NavMenu from './navMenu/NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <>
                <NavMenu />
                {this.props.children}
            </>
        );
    }
}
