//https://gist.github.com/holmberd/945375f099cbb4139e37fef8055bc430

/**
* Creates nested groups by object properties.
* `properties` array nest from highest(index = 0) to lowest level.
*
* @param {String[]} properties
* @returns {Object}
*/
function nestGroupsBy(arr: any, properties: any) {
    properties = Array.from(properties);
    if (properties.length === 1) {
        return groupBy(arr, properties[0]);
    }
    const property = properties.shift();
    const grouped = groupBy(arr, property);
    for (const key in grouped) {
        grouped[key] = nestGroupsBy(grouped[key], Array.from(properties));
    }
    return grouped;
}

/**
 * Group objects by property.
 * `nestGroupsBy` helper method.
 *
 * @param {String} property
 * @param {Object[]} conversions
 * @returns {Object}
 */
function groupBy(conversions: any, property: any) {
    return conversions.reduce((acc: any, obj: any) => {
        const key = obj[property]?.toString()?.trim();
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
}

export { nestGroupsBy, groupBy };