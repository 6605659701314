import {
    GlobalAppActionType
} from "./globalState";
import {
    Dispatch
} from "react";
import axios from "axios";
import {
    displayErrorToast,
} from "../utils/toasts";
import { PolicySearchResult } from "../types/PolicySearchResult";
import { PolicySearchQuery, ReportSearchQuery } from "../pages/search/Search";
import { ReportSearchResult } from "../types/ReportSearchResult";
import { getAlignment, ReportEntry } from "../pages/report/Report";
import { PolicyData } from "../types/PolicyData";
import fileDownload from 'js-file-download';
import { AccountInfo } from "@azure/msal-browser";
import { AadGroupAccess } from "../types/AadGroupAccess";


export function getActionCreators(dispatch: Dispatch<GlobalAppActionType>) {

    const testBackendApiHandlerNoAuth = () => new Promise<string>((resolve, reject) => {
        const sendData = async () => {
            await axios(`WeatherForecast`, {
                method: "GET"
            })
                .then(response => {
                    const data: any = response.data;
                    resolve(data);
                })
                .catch(error => {
                    reject(error);
                });
        };

        return sendData();
    });

    const testBackendApiHandlerWithAuth = (
    ) => new Promise<string>((resolve, reject) => {
        const sendData = async () => {
            await axios(`WeatherForecast/with-auth`, {
                method: "GET"
            })
                .then(response => {
                    const data: any = response.data;

                    // Dispatch if required

                    resolve(data);
                })
                .catch(error => {
                    reject(error);
                });
        };

        return sendData();
    });

    const getPolicySearchResults = (
        searchQuery: PolicySearchQuery
    ) => new Promise<PolicySearchResult[]>((resolve, reject) => {
        const sendData = async () => {
            //clears out report search as we are searching for reports
            dispatch({
                type: "RECEIVE_REPORT_SEARCH",
                payload: [],
            });
            dispatch({
                type: "LOADING_POLICY_SEARCH",
            });
            dispatch({
                type: "SET_SELECTED_POLICY",
                payload: undefined,
            });
            dispatch({
                type: "SET_SELECTED_REPORT",
                payload: undefined,
            });

            dispatch({
                type: "SET_SEARCH_QUERY",
                payload: searchQuery
            });

            await axios(`api/policy`, {
                method: "POST",
                data: JSON.stringify(searchQuery)
            })
                .then(response => {
                    const data: PolicySearchResult[] = response.data;
                    dispatch({
                        type: "RECEIVE_POLICY_SEARCH",
                        //TODO: confirm this order...
                        payload: data.sort((a, b) => a.clientNo.localeCompare(b.clientNo) || a.coverNo.localeCompare(b.coverNo)),
                    });
                    resolve(data);
                })
                .catch(error => {
                    dispatch({
                        type: "ERROR_POLICY_SEARCH",
                        payload: error,
                    });
                    reject(error);
                });
        };

        return sendData();
    });


    const getReportSearchResults = (
        reportSearchQuery: ReportSearchQuery
    ) => new Promise<string>((resolve, reject) => {
        const sendData = async () => {
            //clears out policy search as we are searching for reports
            dispatch({
                type: "RECEIVE_POLICY_SEARCH",
                payload: [],
            });
            dispatch({
                type: "SET_SELECTED_POLICY",
                payload: undefined,
            });
            dispatch({
                type: "SET_SELECTED_REPORT",
                payload: undefined,
            });

            dispatch({
                type: "LOADING_REPORT_SEARCH",
            });

            dispatch({
                type: "SET_REPORT_SEARCH_QUERY",
                payload: reportSearchQuery
            });

            await axios(`api/Report/search`, {
                method: "POST",
                data: reportSearchQuery
            })
                .then(response => {
                    const data: any = response.data;
                    dispatch({
                        type: "RECEIVE_REPORT_SEARCH",
                        payload: data,
                    });
                    resolve(data);
                })
                .catch(error => {
                    dispatch({
                        type: "ERROR_REPORT_SEARCH",
                        payload: error,
                    });
                    reject(error);
                });
        };
        return sendData();
    });

    const getReport = (
        blobUrl: string
    ) => new Promise<string>((resolve, reject) => {
        const sendData = async () => {

            dispatch({
                type: "LOADING_REPORT",
            });

            await axios(`api/Report/file`, {
                method: "POST",
                data: JSON.stringify({ url: blobUrl })
            }).then(response => {
                const data: string = response.data;
                const lines = data.split("\n");
                const b = lines.map(x => {
                    const matches = x.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g) ?? [];
                    if (matches.length < 14) { return null; }
                    const obj = ({
                        content: matches[0]?.replace(/^"(.*)"$/, '$1'),
                        top: Number(matches[1]),
                        left: Number(matches[2]),
                        width: Number(matches[3]),
                        height: Number(matches[4]),
                        bold: Number(matches[6]) === -1,
                        italics: Number(matches[8]) === -1,
                        alignment: getAlignment(Number(matches[10])),
                        pageNumber: Number(matches[11]),
                        isLine: matches[12].replace(/^"(.*)"$/, '$1') === "<C>"
                    } as ReportEntry);
                    return obj;
                }).filter(x => x) as ReportEntry[];

                dispatch({
                    type: "RECEIVE_REPORT",
                    payload: b,
                });
                resolve(data);
            })
                .catch(error => {
                    dispatch({
                        type: "ERROR_REPORT",
                        payload: error,
                    });
                    reject(error);
                });
        };
        return sendData();
    });


    const getPolicyData = (
        policyId: string
    ) => new Promise<PolicyData>((resolve, reject) => {
        const sendData = async () => {
            dispatch({
                type: "LOADING_POLICY_DATA",
            });

            await axios(`api/policy?policyId=${policyId}`, {
                method: "GET"
            }).then(response => {
                const data: PolicyData = response.data;
                dispatch({
                    type: "RECEIVE_POLICY_DATA",
                    payload: data,
                });

                resolve(data);
            })
                .catch(error => {
                    dispatch({
                        type: "ERROR_POLICY_DATA",
                        payload: error,
                    });
                    reject(error);
                });
        };
        return sendData();
    });


    const downloadDataLakeFile = (
        blobUrl: string
    ) => new Promise<Blob>((resolve, reject) => {
        const sendData = async () => {
            await axios(`api/files`, {
                method: "POST",
                data: JSON.stringify({ url: blobUrl }),
                responseType: 'blob'
            })
                .then(res => {
                    if (blobUrl.endsWith(".pdf")) {
                        const file = new Blob([res.data], { type: "application/pdf" })
                        const fileURL = URL.createObjectURL(file)
                        window.open(fileURL, '_blank');
                    } else {
                        const split = blobUrl.split('/');
                        const fileName = split[split.length - 1];
                        fileDownload(res.data, fileName);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        };
        return sendData();
    });

    const getUserGroups = () => new Promise<AadGroupAccess[]>((resolve, reject) => {
        dispatch({
            type: "LOADING_USER_GROUPS",
        });
        const sendData = async () => {
            await axios(`api/User/groups`, {
                method: "GET",
            })
                .then(res => {
                    dispatch({
                        type: "RECEIVE_USER_GROUPS",
                        payload: res.data,
                    });
                })
                .catch(error => {
                    dispatch({
                        type: "ERROR_USER_GROUPS",
                        payload: error,
                    });
                    reject(error);
                });
        };
        return sendData();
    });


    // Action creator definitions
    const actionCreators = {
        loginSuccessful(accessToken: string = '') {
            dispatch({
                type: "LOGIN_SUCCESSFUL",
                accessToken: accessToken
            });
        },

        loginUnsuccessful(errorMessage: string) {
            dispatch({
                type: "LOGIN_UNSUCCESSFUL",
                payload: errorMessage,
            });

            displayErrorToast(errorMessage);
        },

        logoutSuccessful() {
            dispatch({
                type: "LOGOUT_SUCCESSFUL",
            });
        },

        logoutUnsuccessful(errorMessage: string) {
            dispatch({
                type: "LOGOUT_UNSUCCESSFUL",
                payload: errorMessage,
            });

            displayErrorToast(errorMessage);
        },

        testBackendApiHandlerNoAuth,

        testBackendApiHandlerWithAuth,

        setSelectedPolicy(policy: PolicySearchResult | undefined) {
            dispatch({
                type: "SET_SELECTED_POLICY",
                payload: policy,
            });
        },

        setSelectedReport(report: ReportSearchResult | undefined) {
            dispatch({
                type: "SET_SELECTED_REPORT",
                payload: report,
            });
        },

        setAccountToUse(account: AccountInfo | undefined) {
            dispatch({
                type: "SET_ACCOUNT",
                payload: account,
            });
        },

        getUserGroups,

        getPolicySearchResults,

        getReportSearchResults,

        getReport,

        getPolicyData,

        downloadDataLakeFile
    };

    return actionCreators;
}
