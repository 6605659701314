import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ProtectedPageAuthPrompt from '../../components/login/ProtectedPageAuthPrompt';
import SearchControl from '../../components/search/SearchControl';
import SearchResults from '../../components/search/SearchResults';
import { GlobalAppState, GlobalContext } from '../../store/globalState';
import { displayErrorToast } from '../../utils/toasts';
import './Search.scss';

export interface PolicySearchQuery {
    searchText: string;
    schemaCode: string;
    companyCode: string;
    branchCode: string;
    filter: SearchFilter;
    filterToCurrent: boolean;
}

export interface ReportSearchQuery {
    searchText: string;
    schemaCode: string;
}

export enum SearchFilter {
    /* eslint-disable no-unused-vars */
    None = 0,
    InvoiceNumber = 1,
    PolicyNumber = 2,
    CoverNumber = 3,
    ClientNumber = 4,
    ClientName = 5
    /* eslint-enable no-unused-vars */
}
export enum SearchType {
    /* eslint-disable no-unused-vars */
    Policy = 1,
    Report = 2
    /* eslint-enable no-unused-vars */
}

const Search: FC = () => {
    const {
        isUserLoggedIn,
        axiosInterceptorLoaded,
        getPolicySearchResults,
        getReportSearchResults,
        selectedPolicy,
        selectedReport,
        getUserGroups,
        userGroups
    } = React.useContext<GlobalAppState>(GlobalContext);

    const [lastSearchType, setLastSearchType] = useState<SearchType>();

    useEffect(() => {
        if (getUserGroups && axiosInterceptorLoaded) {
            getUserGroups();
        }
    }, [getUserGroups, axiosInterceptorLoaded]);


    return (
        !isUserLoggedIn ?
            <ProtectedPageAuthPrompt />
            :
            <div className="search-page">
                <div className="search-top">
                    {axiosInterceptorLoaded &&
                        <SearchControl
                            userGroups={userGroups}
                            onPolicySearch={(query: PolicySearchQuery) => {
                                getPolicySearchResults(query)
                                    .catch(ex => {
                                        if (ex.isAxiosError && ex.message === "Network Error") {
                                            displayErrorToast("You appear to be offline. Please connect to the internet and try again!");
                                        }
                                    });
                                setLastSearchType(SearchType.Policy);
                            }}
                        onReportSearch={(reportSearchQuery: ReportSearchQuery) => {
                                getReportSearchResults(reportSearchQuery)
                                    .catch(ex => {
                                        if (ex.isAxiosError && ex.message === "Network Error") {
                                            displayErrorToast("You appear to be offline. Please connect to the internet and try again!");
                                        }
                                    });
                                setLastSearchType(SearchType.Report);
                            }}
                        />
                    }
                    <SearchResults lastSearchType={lastSearchType} />
                </div>
                <div className="search-bottom">
                    {
                        (!!selectedPolicy || !!selectedReport) &&
                        <div className="inquiry-container">
                            <div className="container">
                                {!!selectedPolicy &&
                                    <Link className="btn inquiry-btn" to={`/policy/${selectedPolicy.id}`}>
                                        Select Policy
                                    </Link>
                                }
                                {!!selectedReport &&
                                    <Link className="btn inquiry-btn" to={`/report?url=${selectedReport.blobUrl}`}>
                                        Select Report
                                    </Link>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
    );
};

export default Search;