export class Globals {
    static get ROW_HEIGHT(): number { return 40; }
    static get HEADER_HEIGHT(): number { return 50; }
    static get ROW_HEIGHT_LARGE(): number { return 70; }
    static get MAX_HEIGHT(): number { return 500; }
    static get MAX_HEIGHT_LARGE(): number { return 1200; }
    static get REFRESH_TOKEN_EXPIRED(): string { return "BrowserAuthError"; }
}


