import * as React from 'react';
import { FC } from 'react';
import { AutoSizer, Table, Column, ScrollSync, ScrollEventData } from 'react-virtualized';
import { PolicySearchResult } from '../../../types/PolicySearchResult';
import { PolicySearchQuery, SearchFilter } from '../../../pages/search/Search';
import Moment from 'react-moment';
import './Tables.scss';
import { Globals } from '../../../services/globalsService';
import Highlighter from 'react-highlight-words';
import { getInvoiceWithNoPrefix } from '../../../utils/searchHelper';
import { truncateTimeFromDateTime } from '../../../utils/datetime';

interface PolicyVersionResultsProps {
    filteredPolicySearchResults: PolicySearchResult[];
    policySearchQuery: PolicySearchQuery;
    selectedResult: PolicySearchResult | undefined;
    selectedPolicy: PolicySearchResult | undefined;
    setSelectedPolicy: (res: PolicySearchResult) => void;
}

const PolicyVersionResultsTable: FC<PolicyVersionResultsProps> = (props: PolicyVersionResultsProps) => {
    const { policySearchQuery, } = props;
    const versionsRowCount = props.filteredPolicySearchResults.length;
    const versionsTableHeight = Math.min(Globals.MAX_HEIGHT, (versionsRowCount * Globals.ROW_HEIGHT) + Globals.HEADER_HEIGHT);

    const versionRowClassName = (index: number) => {
        let className = "";
        if (index < 0) {
            className += "headerRow";
        }
        else {
            if (props.selectedPolicy && props.selectedPolicy === props.filteredPolicySearchResults[index]) {
                className += " selectedPolicy";
            }
            if (index % 2) {
                className += " evenRow";
            }
        }
        return className;
    };

    const onVersionRowClick = (index: number) => {
        const res = props.filteredPolicySearchResults[index];
        props.setSelectedPolicy(res);
    };

    return (
        <div className="results-table">
            <ScrollSync>
                {({ onScroll, scrollTop }) => (
                    <AutoSizer disableHeight>
                        {({ width }) => (
                            <Table
                                height={versionsTableHeight}
                                width={width}
                                rowHeight={Globals.ROW_HEIGHT}
                                rowCount={versionsRowCount}
                                headerHeight={Globals.HEADER_HEIGHT}
                                scrollTop={scrollTop}
                                rowGetter={({ index }) => props.filteredPolicySearchResults[index]}
                                rowClassName={({ index }) => versionRowClassName(index)}
                                onRowClick={({ index }) => onVersionRowClick(index)}
                                onScroll={onScroll as (info: ScrollEventData) => void}
                                overscanRowCount={10}
                            >
                                <Column
                                    width={40}
                                    label="Company Code"
                                    dataKey="companyCode"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (cellData);

                                    }}
                                />
                                <Column
                                    width={40}
                                    label="Branch Code"
                                    dataKey="branchCode"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (<>{cellData}</>);
                                    }}
                                />
                                <Column
                                    width={100}
                                    label="Client Abbr Name"
                                    dataKey="clientAbbrName"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (((!!policySearchQuery && policySearchQuery.filter === SearchFilter.None) || (policySearchQuery?.filter === SearchFilter.ClientName)) ?
                                            <Highlighter
                                                searchWords={[policySearchQuery.searchText]}
                                                autoEscape={true}
                                                textToHighlight={cellData}
                                            /> : <>{cellData}</>
                                        );
                                    }}
                                />
                                <Column
                                    width={100}
                                    label="Insurer Abbr Name"
                                    dataKey="insurerAbbrName"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (<>{cellData}</>);
                                    }}
                                />
                                <Column
                                    width={100}
                                    label="Client #"
                                    dataKey="clientNo"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (((!!policySearchQuery && policySearchQuery.filter === SearchFilter.None) || (policySearchQuery?.filter === SearchFilter.ClientNumber)) ?
                                            <Highlighter
                                                searchWords={[policySearchQuery.searchText]}
                                                autoEscape={true}
                                                textToHighlight={cellData}
                                            /> : <>{cellData}</>
                                        );
                                    }}
                                />
                                <Column
                                    width={100}
                                    label="Cover #"
                                    dataKey="coverNo"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (((!!policySearchQuery && policySearchQuery.filter === SearchFilter.None) || (policySearchQuery?.filter === SearchFilter.CoverNumber)) ?
                                            <Highlighter
                                                searchWords={[policySearchQuery.searchText]}
                                                autoEscape={true}
                                                textToHighlight={cellData}
                                            /> : <>{cellData}</>
                                        );
                                    }}
                                />
                                <Column
                                    width={40}
                                    label="Version #"
                                    dataKey="versionNo"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (
                                            <>{cellData}</>
                                        );
                                    }}
                                />
                                <Column
                                    width={100}
                                    label="Invoice #"
                                    dataKey="invoiceNo"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        const searchTxt = getInvoiceWithNoPrefix(policySearchQuery.searchText);
                                        return (((!!policySearchQuery && policySearchQuery.filter === SearchFilter.None) || (policySearchQuery?.filter === SearchFilter.InvoiceNumber)) ?
                                            <Highlighter
                                                searchWords={[searchTxt]}
                                                autoEscape={true}
                                                textToHighlight={cellData}
                                            /> : <>{cellData}</>
                                        );
                                    }}
                                />
                                <Column
                                    width={100}
                                    label="Policy #"
                                    dataKey="policyNo"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (((!!policySearchQuery && policySearchQuery.filter === SearchFilter.None) || (policySearchQuery?.filter === SearchFilter.PolicyNumber)) ?
                                            <Highlighter
                                                searchWords={[policySearchQuery.searchText]}
                                                autoEscape={true}
                                                textToHighlight={cellData}
                                            /> : <>{cellData}</>
                                        );
                                    }}
                                />
                                <Column
                                    width={100}
                                    label="Risk"
                                    dataKey="riskCode"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (cellData);

                                    }}
                                />
                                <Column
                                    width={100}
                                    label="Effective Date"
                                    dataKey="effectiveDate"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (
                                            <Moment format={"DD/MM/YYYY"}>{truncateTimeFromDateTime(cellData)}</Moment>
                                        );
                                    }}
                                />
                                <Column
                                    width={100}
                                    label="Renewal Date"
                                    dataKey="renewalDate"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (
                                            <Moment format={"DD/MM/YYYY"}>{truncateTimeFromDateTime(cellData)}</Moment>
                                        );
                                    }}
                                />
                                <Column
                                    width={20}
                                    label="Transaction"
                                    dataKey="transactionCode"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (cellData);

                                    }}
                                />
                                <Column
                                    width={20}
                                    label="Current Version"
                                    dataKey="isCurrent"
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return (<>{cellData ? "Y" : "N"}</>);
                                    }}
                                />
                                {props?.policySearchQuery?.filter === SearchFilter.None &&
                                    <Column
                                        width={100}
                                        label="Type"
                                        dataKey="type"
                                        flexGrow={1}
                                        cellRenderer={({ cellData }) => {
                                            return (<>{cellData}</>);
                                        }}
                                    />
                                }
                            </Table>
                        )}
                    </AutoSizer>
                )}
            </ScrollSync>
        </div >
    );
};

export default PolicyVersionResultsTable;