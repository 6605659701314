import React, { FC, useEffect, useState } from 'react';
import './SearchControl.scss';
import CheckBox from '../checkBox/CheckBox';
import { PolicySearchQuery, ReportSearchQuery, SearchFilter } from '../../pages/search/Search';
import { useContext } from 'react';
import { GlobalAppState, GlobalContext } from '../../store/globalState';
import { AadGroupAccess, GroupType } from "../../types/AadGroupAccess";

interface SearchControlProps {
    userGroups: AadGroupAccess[];
    onPolicySearch: (policySearchQuery: PolicySearchQuery) => void;
    onReportSearch: (reportSearchQuery: ReportSearchQuery) => void;
}

const SearchControl: FC<SearchControlProps> = ({ userGroups, onPolicySearch, onReportSearch }: SearchControlProps) => {
    const {
        policySearchQuery,
        reportSearchQuery
    } = useContext<GlobalAppState>(GlobalContext);

    const [policyQuery, setPolicyQuery] = useState<PolicySearchQuery>(policySearchQuery);
    const [reportQuery, setReportQuery] = useState<ReportSearchQuery>(reportSearchQuery);

    const userGroupsPolicy = userGroups.filter(x => x.groupType === GroupType.POLICY);
    const userGroupsReport = userGroups.filter(x => x.groupType === GroupType.REPORT);

    const getSchemaOptions = (data: AadGroupAccess[]): string[] => [...new Set(data.map(item => item.schemaCode))].sort();
    const getCompanyOptions = (data: AadGroupAccess[], schema: string): string[] => [...new Set(data.filter(item => item.schemaCode === schema || schema === "").map(item => item.companyCode))].sort();
    const getBranchOptions = (data: AadGroupAccess[], schema: string, company: string): string[] => [...new Set(data.filter(item => (item.schemaCode === schema || schema === "") && (item.companyCode === company || company === "")).map(item => item.branchCode))].sort();

    const [schemaOptions, setSchemaOptions] = useState<string[]>([]);
    const [companyOptions, setCompanyOptions] = useState<string[]>([]);
    const [branchOptions, setBranchOptions] = useState<string[]>([]);
    const [reportOptions, setReportOptions] = useState<string[]>([]);

    useEffect(() => {
        if (userGroups.length > 0) {
            const policySchema = policyQuery.schemaCode;
            setSchemaOptions(getSchemaOptions(userGroupsPolicy));

            const policyCompany = policyQuery.companyCode;
            setCompanyOptions(getCompanyOptions(userGroupsPolicy, policySchema));

            const policyBranch = policyQuery.branchCode;
            setBranchOptions(getBranchOptions(userGroupsPolicy, policySchema, policyCompany));

            const reportSchema = reportQuery.schemaCode;
            setReportOptions(getSchemaOptions(userGroupsReport));

            setPolicyQuery(prevState => {
                return ({
                    ...prevState,
                    schemaCode: policySchema,
                    companyCode: policyCompany,
                    branchCode: policyBranch
                });
            });

            setReportQuery(prevState => {
                return ({
                    ...prevState,
                    schemaCode: reportSchema
                });
            });
        }
    }, [userGroups]);

    useEffect(() => {
        setCompanyOptions(getCompanyOptions(userGroupsPolicy, policyQuery.schemaCode));
    }, [policyQuery.schemaCode]);

    useEffect(() => {
        setBranchOptions(getBranchOptions(userGroupsPolicy, policyQuery.schemaCode, policyQuery.companyCode));
    }, [policyQuery.schemaCode, policyQuery.companyCode]);

    return (
        <div className="search-control">
            <div className="left-buffer"></div>
            <div className="cover-search-container">
                <div className="search-title-container">
                    <h1>NZbrokers Search</h1>
                    <div className="group-filter">
                        <>
                            <label>
                                <span>Schema</span>
                                <select
                                    value={policyQuery.schemaCode}
                                    onChange={e => {
                                        const newSchema = e.target.value;
                                        const newCompany = "";
                                        const newBranch = "";
                                        setPolicyQuery(prevState => ({
                                            ...prevState,
                                            schemaCode: newSchema,
                                            companyCode: newCompany,
                                            branchCode: newBranch,
                                        }));
                                    }}
                                >
                                    <option value="">
                                        ALL
                                    </option>
                                    {schemaOptions.map(option => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                            </label>
                            <label>
                                <span>Company</span>
                                <select
                                    value={policyQuery.companyCode}
                                    onChange={e => {
                                        const newCompany = e.target.value;
                                        const newBranch = "";
                                        setPolicyQuery(prevState => ({
                                            ...prevState,
                                            companyCode: newCompany,
                                            branchCode: newBranch,
                                        }));
                                    }}
                                >
                                    <option value="">
                                        ALL
                                    </option>
                                    {companyOptions.map(option => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <label>
                                <span>Branch</span>
                                <select
                                    value={policyQuery.branchCode}
                                    onChange={e =>
                                        setPolicyQuery(prevState => ({
                                            ...prevState,
                                            branchCode: e.target.value,
                                        }))
                                    }
                                >
                                    <option value="">
                                        ALL
                                    </option>
                                    {branchOptions.map(option => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </>
                    </div>
                </div>
                <div className="search-prompt-container">
                    <span>Please enter a Policy Number, Invoice Number, Cover Number, Client Number, or Client Name</span>
                </div>
                <div className="search-inputs-container">
                    <input className="searchbox" placeholder="Search" value={policyQuery.searchText}
                        onChange={e => setPolicyQuery({
                            ...policyQuery,
                            searchText: e.target.value
                        })}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                onPolicySearch(policyQuery);
                            }
                        }}
                    />
                    <div className="checkbox-filter">
                        <CheckBox checked={policyQuery.filterToCurrent}
                            onChange={() => setPolicyQuery({
                                ...policyQuery,
                                filterToCurrent: !policyQuery.filterToCurrent
                            })} />
                        <span onClick={() => setPolicyQuery({
                            ...policyQuery,
                            filterToCurrent: !policyQuery.filterToCurrent
                        })}>Only show current policies</span>
                    </div>
                </div>
                <div className="filters-container">
                    <div className="filters">
                        <span className="filters-title">Filter by:</span>
                        <div className="option"><input type="radio" name="filter-by" id="policy-number" value="Policy Number" defaultChecked={policyQuery.filter === SearchFilter.PolicyNumber} onChange={() => setPolicyQuery({
                            ...policyQuery,
                            filter: SearchFilter.PolicyNumber
                        })} /><label htmlFor="policy-number">Policy Number</label></div>
                        <div className="option"><input type="radio" name="filter-by" id="invoice-number" value="Invoice Number" defaultChecked={policyQuery.filter === SearchFilter.InvoiceNumber} onChange={() => setPolicyQuery({
                            ...policyQuery,
                            filter: SearchFilter.InvoiceNumber
                        })} /><label htmlFor="invoice-number">Invoice Number</label></div>
                        <div className="option"><input type="radio" name="filter-by" id="cover-number" value="Cover Number" defaultChecked={policyQuery.filter === SearchFilter.CoverNumber} onChange={() => setPolicyQuery({
                            ...policyQuery,
                            filter: SearchFilter.CoverNumber
                        })} /><label htmlFor="cover-number">Cover Number</label></div>
                        <div className="option"><input type="radio" name="filter-by" id="client-number" value="Client Number" defaultChecked={policyQuery.filter === SearchFilter.ClientNumber} onChange={() => setPolicyQuery({
                            ...policyQuery,
                            filter: SearchFilter.ClientNumber
                        })} /><label htmlFor="client-number">Client Number</label></div>
                        <div className="option"><input type="radio" name="filter-by" id="client-name" value="Client Name" defaultChecked={policyQuery.filter === SearchFilter.ClientName} onChange={() => setPolicyQuery({
                            ...policyQuery,
                            filter: SearchFilter.ClientName
                        })} /><label htmlFor="client-name">Client Name</label></div>
                    </div>
                    <div className="search-btn-container">
                        <button className="btn" onClick={() => onPolicySearch(policyQuery)}>Search</button>
                    </div>
                </div>
            </div>
            <div className="rpt-search-container">
                <div className="top-buffer"></div>
                <div className="rpt-search">
                    <div className="group-filter">
                        <label>
                            <span>Schema</span>
                            <select
                                value={reportQuery.schemaCode}
                                onChange={e => {
                                    const newSchema = e.target.value;
                                    setReportQuery(prevState => ({
                                        ...prevState,
                                        schemaCode: newSchema,
                                    }));
                                }}
                            >
                                <option value="">
                                    ALL
                                </option>
                                {reportOptions.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <div className="search-prompt-container">
                        <span>Please enter a folder name. For example, the date, run number, or report id</span>
                    </div>
                    <input placeholder="Search" value={reportQuery?.searchText ?? ""}
                        onChange={e => setReportQuery(prevState => ({
                            ...prevState,
                            searchText: e.target.value
                        }))}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                onReportSearch(reportQuery);
                            }
                        }}
                    />
                    <div className="search-btn-container">
                        <button className="btn" onClick={() => onReportSearch(reportQuery)}>Search</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchControl;