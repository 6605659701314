import * as React from "react";
import "./CheckBox.scss";

interface CheckBoxProps {
    checked: boolean;
    onChange?: () => void;
    disabled?: boolean;
}

const CheckBox: React.FC<CheckBoxProps> = (props: CheckBoxProps) => {
    return (
        <div className="custom-checkbox-container">
            <label className="custom-checkbox">
                <input type="checkbox" checked={props.checked} onChange={() => props.onChange && props.onChange()} />
                <span className={"checkmark" + (props.disabled ? " disabled" : "")}></span>
            </label>
        </div>
    );
};

export default CheckBox;