import * as React from 'react';
import {
    FC,
    useEffect,
    useRef,
    useState
} from 'react';
import { ReportEntry } from '../../pages/report/Report';
import './ReportParser.scss';
import { useWindowSize } from '../../utils/window';
import { iconBeginning, IconCompress, IconExpand, iconLast, iconNext, iconPrevious } from '../../images';
import { useHistory } from 'react-router-dom';


interface ReportParserProps {
    reportEntries: ReportEntry[];
}

const FIRST_PAGE: number = 1;

const ReportParser: FC<ReportParserProps> = (props: ReportParserProps) => {
    const size = useWindowSize();
    const history = useHistory();

    const [reportContainerWidth, setReportContainerWidth] = useState<number>(0);
    const [reportContainerHeight] = useState<number>(1600);
    const [maxReportHeight, setMaxReportHeight] = useState<number>(0);
    const [maxReportWidth, setMaxReportWidth] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(FIRST_PAGE);
    const [goToPage, setGoToPage] = useState<number | undefined>(undefined);
    const [maxPage, setMaxPage] = useState<number>(0);
    const [fitToPage, setFitToPage] = useState(false);

    const stageCanvasRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (props.reportEntries.length) {
            const maxHeight = Math.max(...props.reportEntries.map(function (o) { return o.top + o.height + 2; }));
            const maxWidth = Math.max(...props.reportEntries.map(function (o) { return o.left + o.width + 2; }));
            const maxPage = Math.max(...props.reportEntries.map(function (o) { return o.pageNumber; }));
            setMaxReportHeight(maxHeight);
            setMaxReportWidth(maxWidth);
            setMaxPage(maxPage);
        }
    }, [props.reportEntries]);

    //updates the sizes on page resize
    useEffect(() => {
        if (stageCanvasRef.current && stageCanvasRef.current !== null) {
            const width = stageCanvasRef?.current.offsetWidth;
            const height = stageCanvasRef?.current.scrollHeight;
            setReportContainerWidth(width);
            if (size.height > 0 && (size.height - 170) / height < 1 && fitToPage) {
                stageCanvasRef.current.style.transform = "scale(" + ((size.height - 170) / height) + ")";
            }
            else {
                stageCanvasRef.current.style.transform = "scale(1)";
            }
        }
    }, [stageCanvasRef, size, currentPage, fitToPage]);

    //handle events
    const handleFirstPageBtnClick = () => setCurrentPage(FIRST_PAGE);
    const handlePreviousPageBtnClick = () => currentPage > FIRST_PAGE && setCurrentPage(currentPage - 1);
    const handleNextPageBtnClick = () => currentPage < maxPage && setCurrentPage(currentPage + 1);
    const handleLastPageBtnClick = () => setCurrentPage(maxPage);

    const handleGoToOnChange = (e: React.ChangeEvent<HTMLInputElement>) => setGoToPage(e.currentTarget.valueAsNumber);
    const handleGoToSubmit = () => setCurrentPage(goToPage ?? currentPage);
    const handleFitToPage = () => setFitToPage(e => !e);
    const handleGoToSubmitEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleGoToSubmit();
        }
    };
    return (
        <div className="report-parser">
            <div className="navigation">
                <div className="container">
                    <button onClick={handleFirstPageBtnClick} disabled={currentPage === FIRST_PAGE}><img src={iconBeginning} alt="Go to first page" /></button>
                    <button onClick={handlePreviousPageBtnClick} disabled={currentPage === FIRST_PAGE}><img src={iconPrevious} alt="Go to previous page" /></button>
                    <input type="text" value={`Page ${currentPage}`} onChange={() => { }} readOnly />
                    <button onClick={handleNextPageBtnClick} disabled={currentPage === maxPage}><img src={iconNext} alt="Go to next page" /></button>
                    <button onClick={handleLastPageBtnClick} disabled={currentPage === maxPage}><img src={iconLast} alt="Go to last page" /></button>
                    <input className="goto-input" type="number" defaultValue={goToPage} min={FIRST_PAGE} max={maxPage} onChange={handleGoToOnChange} onKeyPress={handleGoToSubmitEnter} />
                    <button className="btn goto-btn" onClick={handleGoToSubmit} disabled={goToPage ? (goToPage < FIRST_PAGE || goToPage > maxPage) : false}>Go To</button>
                    <div className="right">
                        <button className={"btn fit-to-page"} onClick={handleFitToPage}>{fitToPage ? <IconCompress /> : <IconExpand />}</button>
                        <button className="btn back-btn" onClick={() => history.push("/search")}>Back</button>
                    </div>
                </div>
            </div>
            <div className="container" >

                <div className="report" ref={stageCanvasRef}>
                    {
                        !!props.reportEntries.length &&
                        props.reportEntries.reduce(function (arr: ReportEntry[], el) {
                            if (el.pageNumber === currentPage) {
                                arr.push(el);
                            }
                            return arr;
                        }, [])
                            .map((x, i) => {
                                const baseStyle = {
                                    position: "absolute",
                                    top: `calc(${x?.top} / ${maxReportHeight} * ${reportContainerHeight}px)`,
                                    left: `calc(${x?.left} / ${maxReportWidth} * ${reportContainerWidth}px)`,
                                    width: `calc(${x?.width} / ${maxReportWidth} * ${reportContainerWidth}px)`,
                                } as React.CSSProperties;

                                const lineStyle = {
                                    ...baseStyle,
                                    borderBottom: "4px solid black"
                                } as React.CSSProperties;

                                const textStyle = {
                                    ...baseStyle,
                                    textAlign: x?.alignment,
                                    fontWeight: x?.bold ? "bold" : "normal",
                                    fontStyle: x?.italics ? "italic" : "normal"
                                } as React.CSSProperties;

                                const content = x?.content;
                                return (
                                    x?.isLine ?
                                        <div key={i}
                                            style={lineStyle}
                                        ></div>
                                        :
                                        <div key={i}
                                            style={textStyle}
                                        >
                                            <pre>{content}</pre>
                                        </div>
                                );
                            })
                    }
                </div>
            </div>
        </div>
    );
};

export default ReportParser;