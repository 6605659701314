import * as React from 'react';
import {
    createContext,
    useReducer,
    useMemo,
    useEffect,
    useState,
} from "react";
import AppReducer from "./appReducer";
import axios from "axios";
import {
    getActionCreators
} from "./actionCreators";
import {
    useIsAuthenticated,
} from '@azure/msal-react';
import {
    AccountInfo,
    AuthenticationResult,
    InteractionRequiredAuthError,
    IPublicClientApplication,
    PopupRequest,
    PublicClientApplication
} from '@azure/msal-browser';
import { PolicySearchResult } from '../types/PolicySearchResult';
import { PolicyData, WebFormData } from '../types/PolicyData';
import { ReportSearchResult } from '../types/ReportSearchResult';
import { SearchFilter, PolicySearchQuery, ReportSearchQuery } from '../pages/search/Search';
import { AppSettings } from '../types/AppSettings';
import { buildWebApiTokenRequest } from '../components/login/AuthConfig';
import { ReportEntry } from '../pages/report/Report';
import { Globals } from '../services/globalsService';
import { AadGroupAccess } from "../types/AadGroupAccess";

/// Domain Classes


/// Actions
export type GlobalAppActionType =
    | {
        type: 'LOGIN_SUCCESSFUL';
        accessToken: string;
    }
    | {
        type: 'LOGIN_UNSUCCESSFUL';
        payload: string;
    }
    | {
        type: 'LOGOUT_SUCCESSFUL';
    }
    | {
        type: 'LOGOUT_UNSUCCESSFUL';
        payload: string;
    }
    | {
        type: 'SET_ACCOUNT';
        payload: AccountInfo | undefined;
    }
    | {
        type: 'SET_SELECTED_POLICY';
        payload: PolicySearchResult | undefined;
    }
    | {
        type: 'SET_SELECTED_REPORT';
        payload: ReportSearchResult | undefined;
    }
    | {
        type: 'SET_SEARCH_QUERY';
        payload: PolicySearchQuery;
    }
    | {
        type: 'LOADING_POLICY_SEARCH';
    }
    | {
        type: 'ERROR_POLICY_SEARCH';
        payload: string;
    }
    | {
        type: 'RECEIVE_POLICY_SEARCH';
        payload: PolicySearchResult[];
    }
    | {
        type: 'SET_REPORT_SEARCH_QUERY';
        payload: ReportSearchQuery;
    }
    | {
        type: 'LOADING_REPORT_SEARCH';
    }
    | {
        type: 'ERROR_REPORT_SEARCH';
        payload: string;
    }
    | {
        type: 'RECEIVE_REPORT_SEARCH';
        payload: ReportSearchResult[];
    }
    | {
        type: 'LOADING_REPORT';
    }
    | {
        type: 'ERROR_REPORT';
        payload: string;
    }
    | {
        type: 'RECEIVE_REPORT';
        payload: ReportEntry[];
    }
    | {
        type: 'LOADING_POLICY_DATA';
    }
    | {
        type: 'ERROR_POLICY_DATA';
        payload: string;
    }
    | {
        type: 'RECEIVE_POLICY_DATA';
        payload: PolicyData;
    }
    | {
        type: 'LOADING_USER_GROUPS';
    }
    | {
        type: 'ERROR_USER_GROUPS';
        payload: string;
    }
    | {
        type: 'RECEIVE_USER_GROUPS';
        payload: AadGroupAccess[];
    };

/// Global State Definitions
interface GlobalProviderProps {
    msalInstance: PublicClientApplication,
    appSettings: AppSettings,
    children: any;
}

export interface GlobalAppState {
    // Add global state variables below here
    userAccessToken: string | undefined;
    isUserLoggedIn: boolean;
    userAccount: AccountInfo | undefined;
    inheritedMsalInstance: PublicClientApplication | undefined;
    selectedPolicy: PolicySearchResult | undefined;
    loadingPolicySearch: boolean;
    policySearchResults: PolicySearchResult[];
    policySearchQuery: PolicySearchQuery;
    appSettings: AppSettings | undefined;
    reportSearchQuery: ReportSearchQuery;
    reportSearchResults: ReportSearchResult[];
    loadingReportSearch: boolean;
    selectedReport: ReportSearchResult | undefined;
    report: ReportEntry[];
    loadingReport: boolean;
    webFormData: WebFormData[];
    loadingPolicyData: boolean;
    files: PolicySearchResult[];
    loadingUserGroups: boolean;
    userGroups: AadGroupAccess[];

    // Add method stubs for actionCreator methods below here
    /* eslint-disable no-unused-vars */
    setAccountToUse: (account: AccountInfo | undefined) => void,
    loginRequest: (msalInstance: IPublicClientApplication) => void,
    loginSuccessful: (accessToken: string) => void,
    loginUnsuccessful: (errorMessage: string) => void;
    logoutSuccessful: () => void;
    logoutUnsuccessful: (errorMessage: string) => void;
    testBackendApiHandlerNoAuth: () => Promise<any>;
    testBackendApiHandlerWithAuth: () => Promise<any>;
    setSelectedPolicy: (policy: PolicySearchResult | undefined) => void;
    getPolicySearchResults: (query: PolicySearchQuery) => Promise<any>;
    getReportSearchResults: (reportSearchQuery: ReportSearchQuery) => Promise<any>;
    setSelectedReport: (report: ReportSearchResult | undefined) => void;
    getReport: (blobUrl: string) => Promise<any>;
    axiosInterceptorLoaded: boolean;
    getPolicyData: (policyId: string) => Promise<any>;
    downloadDataLakeFile: (blobUrl: string) => Promise<any>;
    getUserGroups: () => Promise<any>;
    /* eslint-enable no-unused-vars */
}

const initialState: GlobalAppState = {
    // Add initial state for state variables here
    userAccessToken: undefined,
    isUserLoggedIn: false,
    userAccount: undefined,
    inheritedMsalInstance: undefined,
    selectedPolicy: undefined,
    loadingPolicySearch: false,
    policySearchResults: JSON.parse(sessionStorage.getItem("policySearchResults") ?? "null") ?? [],
    policySearchQuery: JSON.parse(sessionStorage.getItem("policySearchQuery") ?? "null") ?? { filter: SearchFilter.PolicyNumber, filterToCurrent: true, searchText: "", branchCode: "", companyCode: "", schemaCode: "" },
    appSettings: undefined,
    reportSearchQuery: JSON.parse(sessionStorage.getItem("reportSearchQuery") ?? "null") ?? { schemaCode: "", searchText: "" },
    reportSearchResults: JSON.parse(sessionStorage.getItem("reportSearchResults") ?? "null") ?? [],
    loadingReportSearch: false,
    selectedReport: undefined,
    report: [],
    loadingReport: false,
    axiosInterceptorLoaded: false,
    webFormData: [],
    loadingPolicyData: false,
    files: [],
    userGroups: [],
    loadingUserGroups: false,

    // Add method stubs for actionCreator methods below here
    setAccountToUse: (account: AccountInfo | undefined) => { },
    loginRequest: (msalInstance: IPublicClientApplication) => { },
    loginSuccessful: (accessToken: string) => { },
    loginUnsuccessful: (errorMessage: string) => { },
    logoutSuccessful: () => { },
    logoutUnsuccessful: (errorMessage: string) => { },
    testBackendApiHandlerNoAuth: () => new Promise<any>((resolve, reject) => { }),
    testBackendApiHandlerWithAuth: () => new Promise<any>((resolve, reject) => { }),
    setSelectedPolicy: (policy: PolicySearchResult | undefined) => { },
    getPolicySearchResults: (query: PolicySearchQuery) => new Promise<any>((resolve, reject) => { }),
    getReportSearchResults: (reportSearchQuery: ReportSearchQuery) => new Promise<any>((resolve, reject) => { }),
    setSelectedReport: (report: ReportSearchResult | undefined) => { },
    getReport: (blobUrl: string) => new Promise<any>((resolve, reject) => { }),
    getPolicyData: (policyId: string) => new Promise<any>((resolve, reject) => { }),
    downloadDataLakeFile: (policyId: string) => new Promise<any>((resolve, reject) => { }),
    getUserGroups: () => new Promise<any>((resolve, reject) => { }),
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = (props: GlobalProviderProps) => {
    const isAuthenticated = useIsAuthenticated();
    const [state, dispatch] = useReducer(AppReducer, initialState);
    const [axiosInterceptorLoaded, setAxiosInterceptorLoaded] = useState(false);
    const actionCreators = useMemo(
        () => getActionCreators(dispatch),
        [dispatch]
    );

    //get account to login with
    useEffect(() => {
        if (!!props.msalInstance && !!props.appSettings) {
            const allLoggedInAccounts = props.msalInstance.getAllAccounts();
            let account: AccountInfo | undefined;
            // No cached accounts
            if (!allLoggedInAccounts || allLoggedInAccounts.length < 1) {
                account = undefined;
                console.info(`Could not find any cached accounts, unable to set accountToUse`);
            }
            // Multiple account scenario
            else if (allLoggedInAccounts.length > 1) {
                console.info(`Found ${allLoggedInAccounts.length} accounts across all tenancies`);
                const accountsInAssociatedTenancy: Array<AccountInfo> = allLoggedInAccounts.filter(account =>
                    account.homeAccountId.endsWith(props.appSettings.tenantId)
                );

                if (accountsInAssociatedTenancy.length > 0) {
                    // Default to the first account
                    account = accountsInAssociatedTenancy[0];
                    console.info(`Found ${accountsInAssociatedTenancy.length} accounts in the desired tenancy and selected the first one`);
                }
                else {
                    account = undefined;
                    console.warn(`Found 0 accounts in the desired tenancy, unable to set accountToUse`);
                }
            }
            // Single account scenario
            else if (allLoggedInAccounts.length === 1) {
                account = allLoggedInAccounts[0];
            }
            actionCreators.setAccountToUse(account);
        }
    }, [props.msalInstance, props.appSettings, actionCreators.setAccountToUse]);

    //sets up the axios interceptor
    useEffect(() => {
        if (!!props.msalInstance && !!props.appSettings && !!state.userAccount) {
            console.log('updating axios interceptor using token...');

            //injects bearer token to axios request
            axios.interceptors.request.use(async (req: any) => {
                // state is missing token, so get another one.
                const tokenRequest: PopupRequest = buildWebApiTokenRequest(state.userAccount, props.appSettings.scopes);
                const loginResult = await props.msalInstance
                    .acquireTokenSilent(tokenRequest)
                    .then((tokenResponse: AuthenticationResult) => {
                        if (state.userAccount?.homeAccountId !== tokenResponse.account?.homeAccountId && !!tokenResponse.account) {
                            actionCreators.setAccountToUse(tokenResponse.account);
                        }

                        return tokenResponse;
                    })
                    .catch(async (error) => {
                        if (error instanceof InteractionRequiredAuthError || error.name === Globals.REFRESH_TOKEN_EXPIRED) {
                            // fallback to interaction when silent call fails
                            const popupAuthenticationResult = await props.msalInstance.acquireTokenPopup(tokenRequest);
                            return popupAuthenticationResult;
                        }
                    })
                    .catch((error) => {
                        console.error(`error is ${JSON.stringify(error)}`);
                    });
                if (loginResult) {
                    actionCreators.loginSuccessful(loginResult.accessToken);
                    req.headers.authorization = `Bearer ${loginResult.accessToken}`;
                }
                req.headers['Content-Type'] = 'application/json';

                return req;
            });
            setAxiosInterceptorLoaded(true);
        }
    }, [state.userAccount, props.msalInstance, props.appSettings, actionCreators.loginSuccessful, actionCreators.setAccountToUse]);

    return (
        <GlobalContext.Provider
            value={{
                ...state,
                ...actionCreators,
                isUserLoggedIn: isAuthenticated,
                inheritedMsalInstance: props.msalInstance,
                appSettings: props.appSettings,
                axiosInterceptorLoaded: axiosInterceptorLoaded
            }}
        >
            {props.children}
        </GlobalContext.Provider>
    );
};