import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { AutoSizer, Column, ScrollEventData, ScrollSync, Table } from 'react-virtualized';
import { Globals } from '../../services/globalsService';
import { PolicySearchResult } from '../../types/PolicySearchResult';
import Spinner from '../spinner/Spinner';
import { iconPdf, iconWebsite, iconWorddoc } from './../../images/index';
import './FilesTableView.scss';
import { cloneDeep } from 'lodash';

interface filesTableViewProps {
    files: PolicySearchResult[];
    fileClickCallBack: (url: string) => void;
    isLoading: boolean;
}

const FilesTableView: FC<filesTableViewProps> = (props: filesTableViewProps) => {
    const [sortedFiles, setSortedFiles] = useState<PolicySearchResult[]>([]);

    const reportsRowCount = props.files?.length ?? 0;
    const reportsTableHeight = Math.min(Globals.MAX_HEIGHT_LARGE, (reportsRowCount * Globals.ROW_HEIGHT_LARGE) + Globals.HEADER_HEIGHT);

    const getIcon = (blobUrl: string) => {
        const split = blobUrl.split(".");

        //default icon
        if (split.length === 0) {
            return iconWebsite;
        }

        const fileType = split[split.length - 1]?.toLocaleLowerCase();
        switch (fileType) {
            case "pdf":
                return iconPdf;
            case "doc":
                return iconWorddoc;
            default:
                return iconWebsite;
        }
    };

    const getDescription = (type: string) => {
        switch (type?.toLocaleLowerCase()) {
            case "pdv":
                return "Client Coverage Summary";
            case "creditinvoice":
                return "Credit Invoice";
            case "debitinvoice":
                return "Debit Invoice";
            case "directclientinvoice":
                return "Direct Client Invoice";
            case "feecredit":
                return "Fee Credit";
            case "feedebit":
                return "Fee Debit";
            case "insurerclosing":
                return "Insurer Confirmation";
            default:
                return type;
        }
    };

    const reportRowClassName = (index: number) => {
        let className = "";
        if (index < 0) {
            className += "headerRow";
        }
        return className;
    };

    const onReportRowClick = (index: number) => {
        const res = sortedFiles[index];
        props.fileClickCallBack(res.blobUrl);
    };

    useEffect(() => {
        if (props.files?.length) {
            const files = cloneDeep(props.files);
            //sort the files
            //order: details a-z, then isMainInvoice true - false, then invoiceNumber high-low
            files.sort(
                (a: PolicySearchResult, b: PolicySearchResult) => getDescription(a.type).localeCompare(getDescription(b.type)) || (b?.isMainInvoice?.toString() ?? "").localeCompare(a?.isMainInvoice?.toString() ?? "") || b.invoiceNo.localeCompare(a.invoiceNo)
            );
            setSortedFiles(files);
        }

    }, [props.files]);


    return (
        <div id="results-table">
            {
                props.isLoading ?
                    <Spinner /> :
                    sortedFiles && !!sortedFiles.length ?
                        <ScrollSync>
                            {({ onScroll, scrollTop }) => (
                                <AutoSizer disableHeight>
                                    {({ width }) => (
                                        <Table
                                            height={reportsTableHeight}
                                            width={width}
                                            rowHeight={Globals.ROW_HEIGHT_LARGE}
                                            rowCount={reportsRowCount}
                                            headerHeight={Globals.HEADER_HEIGHT}
                                            scrollTop={scrollTop}
                                            rowGetter={({ index }) => sortedFiles[index]}
                                            rowClassName={({ index }) => reportRowClassName(index)}
                                            onRowClick={({ index }) => onReportRowClick(index)}
                                            onScroll={onScroll as (info: ScrollEventData) => void}
                                            overscanRowCount={30}
                                        >
                                            <Column
                                                width={100}
                                                label="Type"
                                                dataKey="blobUrl"
                                                className="center-column"
                                                headerClassName="center-column"
                                                cellRenderer={({ cellData }) => {
                                                    return (
                                                        <img className="type" src={getIcon(cellData)} alt="type" />
                                                    );
                                                }}
                                            />
                                            <Column
                                                width={90}
                                                label="Details"
                                                dataKey="type"
                                                flexGrow={1}
                                                cellRenderer={({ cellData }) => {
                                                    return (
                                                        <span className="details">{getDescription(cellData)}</span>
                                                    );
                                                }}
                                            />
                                            <Column
                                                width={40}
                                                label="Invoice Number"
                                                dataKey="invoiceNo"
                                                flexGrow={1}
                                                cellRenderer={({ cellData }) => {
                                                    return (
                                                        <span className="">{cellData}</span>
                                                    );
                                                }}
                                            />
                                            <Column
                                                width={40}
                                                label="Client Abbr. Name"
                                                dataKey="clientAbbrName"
                                                flexGrow={1}
                                                cellRenderer={({ cellData }) => {
                                                    return (
                                                        <span className="">{cellData}</span>
                                                    );
                                                }}
                                            />
                                            <Column
                                                width={40}
                                                label="Insurer Abbr. Name"
                                                dataKey="insurerAbbrName"
                                                flexGrow={1}
                                                cellRenderer={({ cellData }) => {
                                                    return (
                                                        <span className="">{cellData}</span>
                                                    );
                                                }}
                                            />
                                            <Column
                                                width={40}
                                                label="Is Main Invoice"
                                                dataKey="isMainInvoice"
                                                flexGrow={1}
                                                cellRenderer={({ cellData }) => {
                                                    return (
                                                        <span className="">{cellData !== null && (cellData ? "Y" : "N")}</span>
                                                    );
                                                }}
                                            />
                                        </Table>
                                    )}
                                </AutoSizer>
                            )}
                        </ScrollSync>
                        : <div className="no-results">
                            <h3>We couldn't find any related files</h3>
                            <span>Please ensure you have selected the correct version of your policy.</span>
                        </div>
            }
        </div>
    );
};

export default FilesTableView;